import { DownloadImageFooter } from 'client/shared/components/download-image-footer';
import * as React from 'react';
import './styles.scss';
import { BenchmarkFilter } from 'client/shared/graphql-client/graphql-operations.g';
import { getFilteredBenchmarkFooter } from 'client/shared/core/performance-data';
import {
  StatisticType,
  TrackAreaMode,
  TrackDatum,
  TrackVariable,
  VisualizationType,
} from 'core';
import { useGenerateImage } from 'client/shared/hooks';
import { EmbedLogoFooter } from '../embed-logo-footer';
import { EmbedDataPointContext } from '../visualization-picker';
import { TrackDatePill } from '../domain-card';
import MapView from '@arcgis/core/views/MapView';
import {
  enabledOptionState,
  hiddenOptionState,
  SaveableDataPoint,
  TrackCardOptionsMenu,
  TrackCardOptionType,
} from '../track-card-options-menu';

const baseClass = 'pn-dashboard-card';

export interface Props extends SaveableDataPoint {
  readonly chartTypeToSave?: VisualizationType;
  readonly className?: string;
  readonly description?: string;
  readonly title: string | JSX.Element;
  readonly imageGenerationInfo?: {
    readonly name: string;
    readonly buttonClass: string;
  };
  readonly recentValue?: TrackDatum;
  readonly variable?: TrackVariable;
  readonly benchmarkFilter: BenchmarkFilter;
  readonly embedContext?: EmbedDataPointContext;
  readonly comparisonGroupId?: string;
  readonly currentFips?: string;
  readonly areaMode: TrackAreaMode;
}

interface MapViewContext {
  readonly setView: React.Dispatch<React.SetStateAction<MapView | null>>;
}
export const MapViewContext = React.createContext<MapViewContext | null>(null);
export const DashboardCard: React.FC<Props> = (p) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [mapView, setMapView] = React.useState<MapView | null>(null);
  const imageMapData = React.useMemo(
    () => ({
      mapView: mapView ?? undefined,
      mapContainerClass: '.pn-track-map',
      mapLegendClass: '.pn-track-map-legend',
      classesToRemove: [
        '.pn-track-view-selector',
        '.pn-index-scores-chart-container-collapse-btns',
      ],
    }),
    [mapView]
  );
  const generateImage = useGenerateImage(ref, {
    imageName: `${p.variable?.label} indicator`,
    mapData: imageMapData,
  });
  const { recentValue, variable } = p;
  return (
    <div
      className={`${baseClass} p-3 ${!!p.embedContext ? 'my-0' : 'mt-4'} ${
        p.className ?? ''
      } ${p.embedContext === EmbedDataPointContext.VISUALIZATION ? 'border-0' : ''}`}
      ref={ref}
    >
      <div className={`${baseClass}-header py-1 d-flex justify-content-between`}>
        {typeof p.title === 'string' ? <h3>{p.title}</h3> : p.title}
        <div className="d-flex">
          {recentValue && (
            <TrackDatePill
              date={recentValue.recordedAt}
              isIndexScore={variable?.statisticType === StatisticType.INDEX}
            />
          )}
          <TrackCardOptionsMenu
            generateImage={{
              type: TrackCardOptionType.SAVE_IMAGE,
              state: enabledOptionState(generateImage),
            }}
            saveVisualization={p.saveVisualization}
            setGoalModalOpen={{
              type: TrackCardOptionType.OPEN_GOAL_MODAL,
              state: hiddenOptionState(),
            }}
          />
        </div>
      </div>
      {!!p.description && (
        <div className={`${baseClass}-description mb-3`}>{p.description}</div>
      )}
      <MapViewContext.Provider value={{ setView: setMapView }}>
        {p.children}
      </MapViewContext.Provider>
      {p.imageGenerationInfo && (
        <DownloadImageFooter
          footer={
            p.areaMode === TrackAreaMode.LOCAL
              ? getFilteredBenchmarkFooter(p.benchmarkFilter)
              : ''
          }
        />
      )}
      {p.embedContext === EmbedDataPointContext.VISUALIZATION && (
        <EmbedLogoFooter className="pt-2" />
      )}
    </div>
  );
};
