import { FetchResult } from '@apollo/client';
import { AiAssistantId } from '@polco-us/types';
import {
  AiAssistantMessage,
  AiAssistantMessageRole,
  AiAssistantMessageType,
} from 'client/admin/core';
import { aiAssistantMessageClientToGql } from 'client/admin/graphql-util/transforms/ai-assistant-session';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { readQueryFor, writeQueryFor } from 'client/shared/containers/query';
import { AdminAiAssistantUploadFile } from 'client/shared/graphql-client/graphql-operations.g';
import { MutationInfos } from 'client/shared/graphql-mutations';
import { QueryInfos } from 'client/shared/graphql-queries';
import { GqlError, PolcoGqlError, PolcoGqlErrors, Result } from 'core';
export interface UseAiAssistantFileUploadProps {
  readonly assistantId: AiAssistantId;
  readonly sessionId: string;
}

export function useAiAssistantFileUpload(props: UseAiAssistantFileUploadProps) {
  const { assistantId, sessionId } = props;
  const { fn: aiAssistantUploadFile, result } = useMutationInfo(
    MutationInfos.adminAiAssistantUploadFile,
    {
      update: (cache, mutationResult) => {
        const newMessageContent = mutationResult.data?.adminAiAssistantUploadFile;
        if (!newMessageContent) {
          return;
        }
        const existingConversationData = readQueryFor(
          cache,
          QueryInfos.currentAiAssistantSessionConversation
        )({ assistantId, sessionId });
        if (
          !existingConversationData ||
          !existingConversationData.currentAiAssistantSession.conversation
        ) {
          return;
        }
        const newMessage: AiAssistantMessage = {
          type: AiAssistantMessageType.SINGLE,
          role: AiAssistantMessageRole.USER,
          content: newMessageContent,
          timestamp: new Date(),
        };
        writeQueryFor(cache, QueryInfos.currentAiAssistantSessionConversation)(
          { sessionId, assistantId },
          {
            currentAiAssistantSession: {
              ...existingConversationData.currentAiAssistantSession,
              conversation: {
                ...existingConversationData.currentAiAssistantSession.conversation,
                messages: [
                  ...(existingConversationData.currentAiAssistantSession.conversation
                    ?.messages ?? []),
                  aiAssistantMessageClientToGql(newMessage),
                ],
              },
            },
          }
        );
      },
    }
  );

  const uploadFile = async (file: string, fileName: string) => {
    const uploadResult = await Result.fromPromise<
      FetchResult<AdminAiAssistantUploadFile>,
      GqlError<PolcoGqlError<PolcoGqlErrors.PolcoApiError>>
    >(
      aiAssistantUploadFile({
        variables: {
          assistantId,
          sessionId,
          file,
          fileName,
        },
      })
    );

    return uploadResult;
  };

  return {
    loading: result.loading,
    data: result.data?.adminAiAssistantUploadFile,
    uploadFile,
  };
}
