import * as React from 'react';
import { useMutationInfo } from 'client/shared/containers/mutation';
import { MutationInfos } from 'client/shared/graphql-mutations';
import * as Gql from 'client/shared/graphql-client/graphql-operations.g';

interface Props {
  readonly className?: string;
  readonly event: CrmTrackedEvent;
  readonly children: React.ReactNode;
}

export enum CrmTrackedEvent {
  ADMIN_VIEWED_DEMO_SITE = 'ADMIN_VIEWED_DEMO_SITE',
  ADMIN_VIEWED_LEARN_MORE_ITEM_NES = 'ADMIN_VIEWED_LEARN_MORE_ITEM_NES',
  ADMIN_VIEWED_LEARN_MORE_ITEM_NCS = 'ADMIN_VIEWED_LEARN_MORE_ITEM_NCS',
  ADMIN_VIEWED_LEARN_MORE_ITEM_NCS_S = 'ADMIN_VIEWED_LEARN_MORE_ITEM_NCS_S',
  ADMIN_VIEWED_LEARN_MORE_ITEM_CASOA = 'ADMIN_VIEWED_LEARN_MORE_ITEM_CASOA',
  ADMIN_VIEWED_LEARN_MORE_ITEM_NBS = 'ADMIN_VIEWED_LEARN_MORE_ITEM_NBS',
  ADMIN_VIEWED_LEARN_MORE_ITEM_NLES = 'ADMIN_VIEWED_LEARN_MORE_ITEM_NLES',
  ADMIN_VIEWED_LEARN_MORE_ITEM_NES_LE = 'ADMIN_VIEWED_LEARN_MORE_ITEM_NES_LE',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_COMMUNITY_CHARACTERISTICS = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_COMMUNITY_CHARACTERISTICS',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_COMMUNITY_DESIGN = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_COMMUNITY_DESIGN',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_COMMUNITY_LIVABILITY = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_COMMUNITY_LIVABILITY',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_ECONOMY = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_ECONOMY',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_EDUCATION_ARTS_CULTURE = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_EDUCATION_ARTS_CULTURE',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_GOVERNANCE = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_GOVERNANCE',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_HEALTH_AND_WELLNESS = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_HEALTH_AND_WELLNESS',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_INCLUSIVITY_AND_ENGAGEMENT = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_INCLUSIVITY_AND_ENGAGEMENT',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_MOBILITY = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_MOBILITY',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_NATURAL_ENVIRONMENT = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_NATURAL_ENVIRONMENT',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_PARKS_AND_RECREATION = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_PARKS_AND_RECREATION',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_SAFETY = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_SAFETY',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_UTILITIES = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_UTILITIES',
  ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_FINANCE = 'ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_FINANCE',
}

export const TrackEventInCrm: React.FC<Props> = (props: Props) => {
  const gqlEvent = trackedEvent_clientToGql(props.event);
  const { fn: trackEventFn } = useMutationInfo(MutationInfos.adminTrackEventInCrm, {
    variables: { event: gqlEvent },
  });
  return (
    <div
      className={props.className ?? ''}
      onClick={() => trackEventFn()}
      role="presentation"
    >
      {props.children}
    </div>
  );
};

function trackedEvent_clientToGql(event: CrmTrackedEvent): Gql.CrmTrackedEvent {
  switch (event) {
    case CrmTrackedEvent.ADMIN_VIEWED_DEMO_SITE:
      return Gql.CrmTrackedEvent.ADMIN_VIEWED_DEMO_SITE;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_CASOA:
      return Gql.CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_CASOA;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_NBS:
      return Gql.CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_NBS;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_NCS:
      return Gql.CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_NCS;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_NCS_S:
      return Gql.CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_NCS_S;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_NES:
      return Gql.CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_NES;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_NES_LE:
      return Gql.CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_NES_LE;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_NLES:
      return Gql.CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_NLES;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK:
      return Gql.CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_COMMUNITY_CHARACTERISTICS:
      return Gql.CrmTrackedEvent
        .ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_COMMUNITY_CHARACTERISTICS;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_COMMUNITY_DESIGN:
      return Gql.CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_COMMUNITY_DESIGN;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_COMMUNITY_LIVABILITY:
      return Gql.CrmTrackedEvent
        .ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_COMMUNITY_LIVABILITY;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_ECONOMY:
      return Gql.CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_ECONOMY;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_EDUCATION_ARTS_CULTURE:
      return Gql.CrmTrackedEvent
        .ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_EDUCATION_ARTS_CULTURE;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_GOVERNANCE:
      return Gql.CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_GOVERNANCE;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_HEALTH_AND_WELLNESS:
      return Gql.CrmTrackedEvent
        .ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_HEALTH_AND_WELLNESS;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_INCLUSIVITY_AND_ENGAGEMENT:
      return Gql.CrmTrackedEvent
        .ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_INCLUSIVITY_AND_ENGAGEMENT;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_MOBILITY:
      return Gql.CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_MOBILITY;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_NATURAL_ENVIRONMENT:
      return Gql.CrmTrackedEvent
        .ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_NATURAL_ENVIRONMENT;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_PARKS_AND_RECREATION:
      return Gql.CrmTrackedEvent
        .ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_PARKS_AND_RECREATION;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_SAFETY:
      return Gql.CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_SAFETY;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_UTILITIES:
      return Gql.CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_UTILITIES;
    case CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_FINANCE:
      return Gql.CrmTrackedEvent.ADMIN_VIEWED_LEARN_MORE_ITEM_TRACK_FINANCE;
  }
}
