import React from 'react';
import { useAdminPermissions } from 'client/admin/hooks/use-admin-permissions';
import { PermissionType } from '@polco-us/types';
import { useSavedDataPoints } from 'client/admin/hooks';
import { useTrackGoals } from 'client/admin/hooks/use-track-goals';
import _ from 'lodash';
import {
  Visualization,
  VisualizationProps,
} from 'client/shared/components/visualization-picker';
import {
  ActivationState,
  TrackVariableWithDistributions,
  VisualizationType,
} from 'core';

export const AdminVisualizationPicker: React.FC<
  Omit<VisualizationProps, 'saveVisualization'> & {
    readonly trackVariable: TrackVariableWithDistributions;
  }
> = (p) => {
  const { doesCurrentAdminHavePermissions: havePermissions } = useAdminPermissions();
  const canManageSavedDataPoints = havePermissions([
    PermissionType.MANAGE_SAVED_DATA_POINTS,
  ]);

  const { saveVisualization, activeVisualizations, publisherMax } =
    useSavedDataPoints({
      publishingEntityId: p.publishingEntityId,
      loadVisualizationData: false,
    });

  const { strategicPlan } = useTrackGoals({
    publishingEntityId: p.publishingEntityId,
  });
  const currentGoal = strategicPlan?.goals.find(
    (goal) => goal.variable.id === p.trackVariable.id
  );

  const isPubMaxVis = !publisherMax || activeVisualizations.length >= publisherMax;

  return (
    <Visualization
      {...p}
      disableSaveDataPointsReason={
        isPubMaxVis ? 'Maximum saved data points reached.' : undefined
      }
      goal={currentGoal}
      hasManageSavedDataPointsPerm={canManageSavedDataPoints}
      onSaveVisualization={async (chartType: VisualizationType | null) =>
        await saveVisualization({
          baseFips: p.baseFips,
          groupFips: _.compact(
            p.trackVariable.areasData.map(
              (ad) => ad.performanceData.at(0)?.fipsArea.id
            )
          ),
          recordedAt:
            _.max(
              p.trackVariable.areasData.flatMap((ad) =>
                ad.performanceData.map((pd) => pd.recordedAt)
              )
            ) ?? null,
          variableId: p.trackVariable.id,
          benchmarkFilter: p.benchmarkFilter,
          visualizationType: chartType,
          state: ActivationState.ACTIVE,
          comparisonGroupId: p.comparisonGroupId ?? null,
        })
      }
      strategicPlanId={strategicPlan?.id}
    />
  );
};
AdminVisualizationPicker.displayName = 'AdminVisualizationPicker';
