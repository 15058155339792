import { domainLabels } from 'client/shared/core/track/labels';
import { AnalyticsDomain } from 'graphql-schema/schema-types.g';

export interface AiPrompt {
  readonly labelQuestion: string;
  readonly prompt: string;
}

export const prompts: Record<string, AiPrompt> = {
  //Assess
  ['benchmark-surveys-overview']: {
    labelQuestion: 'Which Survey Is Right for Me?',
    prompt:
      "Help me choose the most suitable benchmark survey for my community's needs. Compare options like the National Community Survey (NCS), Community Assessment Survey for Older Adults (CASOA), and National Business Survey (NBS). Include what each survey is best used for and how it aligns with goals like measuring resident satisfaction or planning economic strategies.",
  },
  ['benchmark-surveys-more-info']: {
    labelQuestion: 'How Can Benchmark Data Help?',
    prompt:
      'Explain how benchmark survey data can be used to improve community decision-making. Include examples of how to apply survey insights in strategic planning, resource allocation, or public engagement. Highlight specific use cases for surveys like the NCS or CASOA.',
  },

  // Track
  ['cls-background']: {
    labelQuestion: 'What is this and how can I use it?',
    prompt:
      'Fully describe the Community Livability Snapshot (CLS). Include an explanation of what it is, how to use it as a preliminary assessment tool, its purpose, and how doing a National Community Survey (NCS) would impact it. Use the knowledge base to provide a clear and comprehensive overview.',
  },
  ['track-overview-explanation']: {
    labelQuestion: 'How Do I Understand This Page?',
    prompt:
      'Explain the structure and key features of the Track Overview Dashboard. Describe how the data is organized across domains, what the index scores represent, and how to use this page to evaluate community performance. Include tips for interpreting trends and benchmarks.',
  },
  ['histograms-explanation']: {
    labelQuestion: 'How Do I Read Histograms?',
    prompt:
      'Provide a guide for understanding histograms on the Track Overview Dashboard. Explain what the bars represent, how to interpret percentile rankings, and how to identify key insights or patterns from the data. Include examples for clarity.',
  },

  // Track Index Scores Overview
  [`${AnalyticsDomain.ECONOMY}-overview`]: getTrackDomainOverviewPrompt(
    AnalyticsDomain.ECONOMY
  ),

  [`${AnalyticsDomain.EDUCATION_ARTS_CULTURE}-overview`]:
    getTrackDomainOverviewPrompt(AnalyticsDomain.EDUCATION_ARTS_CULTURE),
  [`${AnalyticsDomain.HEALTH_AND_WELLNESS}-overview`]: getTrackDomainOverviewPrompt(
    AnalyticsDomain.HEALTH_AND_WELLNESS
  ),
  [`${AnalyticsDomain.COMMUNITY_DESIGN}-overview`]: getTrackDomainOverviewPrompt(
    AnalyticsDomain.COMMUNITY_DESIGN
  ),
  [`${AnalyticsDomain.INCLUSIVITY_AND_ENGAGEMENT}-overview`]:
    getTrackDomainOverviewPrompt(AnalyticsDomain.INCLUSIVITY_AND_ENGAGEMENT),
  [`${AnalyticsDomain.MOBILITY}-overview`]: getTrackDomainOverviewPrompt(
    AnalyticsDomain.MOBILITY
  ),
  [`${AnalyticsDomain.NATURAL_ENVIRONMENT}-overview`]: getTrackDomainOverviewPrompt(
    AnalyticsDomain.NATURAL_ENVIRONMENT
  ),
  [`${AnalyticsDomain.PARKS_AND_RECREATION}-overview`]: getTrackDomainOverviewPrompt(
    AnalyticsDomain.PARKS_AND_RECREATION
  ),
  [`${AnalyticsDomain.SAFETY}-overview`]: getTrackDomainOverviewPrompt(
    AnalyticsDomain.SAFETY
  ),
  [`${AnalyticsDomain.UTILITIES}-overview`]: getTrackDomainOverviewPrompt(
    AnalyticsDomain.UTILITIES
  ),
  [`${AnalyticsDomain.COMMUNITY_LIVABILITY}-overview`]: getTrackDomainOverviewPrompt(
    AnalyticsDomain.COMMUNITY_LIVABILITY
  ),
  [`${AnalyticsDomain.GOVERNANCE}-overview`]: getTrackDomainOverviewPrompt(
    AnalyticsDomain.GOVERNANCE
  ),

  // Track Index Scores
  [AnalyticsDomain.ECONOMY]: {
    labelQuestion: 'Plan for Economic Growth',
    prompt:
      "Develop a strategic plan to boost our community's economy. Use data from the dashboard, such as job growth trends and business numbers, to suggest actionable steps. Include recommendations for engaging stakeholders, addressing barriers, and leveraging unique community strengths to attract industries or create jobs.",
  },

  // Track Variables
  ['bric_housing_infra_sub_index_score']: {
    labelQuestion: 'Why Is the BRIC Sub-Index Significant?',
    prompt:
      "Discuss the importance of the BRIC Infrastructure/Built Environment/Housing Sub-Index. Explain its role in understanding a community's resilience to shocks, its relevance for disaster planning, and its implications for long-term sustainability.",
  },
  ['fair_market_rent_1_bed']: {
    labelQuestion: 'What Affects Fair Market Rent for 1-Bedroom Apartments?',
    prompt:
      'Analyze the factors contributing to the Fair Market Rent (FMR) for 1-Bedroom Apartments in the community, including housing demand, supply constraints, regional income levels, and local economic conditions. Identify trends or disparities that may require attention.',
  },
  ['fair_market_rent_2_bed']: {
    labelQuestion: 'Why Is Fair Market Rent Important?',
    prompt:
      'Explain why the metric for Fair Market Rent (FMR) for a 2-Bedroom Apartment is important and how it connects to broader issues like housing stability, cost of living, and socioeconomic equity.',
  },
  ['fair_market_rent_3_bed']: {
    labelQuestion: 'What Affects Fair Market Rent for 3-Bedroom Apartments?',
    prompt:
      'Analyze the factors contributing to the Fair Market Rent (FMR) for 3-Bedroom Apartments in the community, including housing demand for families, regional income levels, and economic trends. Identify disparities or trends that may require further investigation.',
  },
  ['fair_market_rent_4plus_bed']: {
    labelQuestion: 'How Can We Make Larger Units Affordable?',
    prompt:
      'Suggest strategies to address challenges related to high Fair Market Rent (FMR) for 4+ Bedroom Apartments, focusing on increasing the supply of larger affordable units, supporting housing subsidies, and incentivizing the development of multi-bedroom rentals.',
  },
  ['fair_market_rent_studio']: {
    labelQuestion: 'Why Is FMR for Studios Important?',
    prompt:
      'Explain why the metric for Fair Market Rent (FMR) for Studio Apartments is important and how it connects to broader issues like housing accessibility for single individuals, cost of living, and urban housing policies.',
  },
  ['geographical_mobility_same_house_pct']: {
    labelQuestion: 'What Is Geographical Mobility?',
    prompt:
      "Explain the concept of 'Geographical Mobility,' including its definition, how it is measured, and what it reveals about population movement and community dynamics.",
  },
  ['households_costs_less_than_25_pct']: {
    labelQuestion: 'What Does It Mean to Spend Less Than 25% of Income on Housing?',
    prompt:
      "Explain the concept of 'Percent of Households in Owner-Occupied Homes Where Monthly Housing Costs Are Less Than 25% of Income,' including its calculation, why the 25% threshold is considered a standard for affordability, and how this reflects financial stability.",
  },
  ['housing_burden_owner_rate']: {
    labelQuestion: 'What Is Housing Cost Burden for Homeowners?',
    prompt:
      "Explain the concept of 'Housing Cost Burden for Homeowners,' including its definition, how it is measured, and why it is a critical indicator for assessing housing affordability.",
  },
  ['housing_burden_rate']: {
    labelQuestion: 'What Is Housing Cost Burden for Renters?',
    prompt:
      "Explain the concept of 'Housing Cost Burden for Renters,' including its definition, how it is measured, and why it is a critical indicator for understanding housing affordability challenges faced by renters.",
  },
  ['housing_overcrowding_rate']: {
    labelQuestion: 'What Does It Mean for Housing Units to Be Overcrowded?',
    prompt:
      "Explain the concept of 'Occupied Housing Units That Are Overcrowded,' including how overcrowding is defined (e.g., persons per room), how it is measured, and why it is a critical indicator of housing adequacy and quality.",
  },
  ['housing_problem']: {
    labelQuestion: 'How Can We Solve Housing Problems?',
    prompt:
      'Suggest strategies to reduce the percentage of Households Experiencing a Severe Housing Problem, focusing on expanding affordable housing, improving infrastructure, offering rental assistance, and addressing systemic barriers to housing stability.',
  },
  ['low_income_grocery_store_access']: {
    labelQuestion: 'How Can We Address Food Insecurity?',
    prompt:
      'Suggest strategies to reduce the percentage of Residents Who Are Low-Income and Have Limited Access to Healthy Foods, focusing on expanding affordable grocery options, supporting community food programs, and addressing transportation barriers to access.',
  },
  ['median_age_same_house']: {
    labelQuestion: 'Why Is Median Age of Stable Residents Important?',
    prompt:
      'Discuss the importance of understanding the median age of those who lived in the same house one year ago and its implications for community cohesion, resource planning, and housing trends.',
  },
  ['median_home_value']: {
    labelQuestion: 'Why Is Median Home Value Important?',
    prompt:
      'Discuss the significance of median home value as a measure of housing affordability, wealth distribution, and economic trends. Explain its impact on homeowners, potential buyers, and overall community stability.',
  },
  ['multifamily_pct']: {
    labelQuestion: 'What Affects the Availability of Multifamily Housing?',
    prompt:
      'Analyze the factors that influence the availability of multifamily housing, such as zoning laws, economic conditions, population density, and development trends.',
  },
  ['multifamily_pct_oa']: {
    labelQuestion:
      'Why Is Multifamily Housing Occupancy Important for Older Adults?',
    prompt:
      'Discuss the importance of multifamily housing for householders aged 65+, focusing on its role in aging in place, community inclusion, and addressing housing affordability and accessibility challenges.',
  },
  ['no_mortgage_pct']: {
    labelQuestion: 'Why Is Mortgage-Free Homeownership Significant?',
    prompt:
      'Discuss the importance of mortgage-free homeownership as an indicator of financial security, wealth accumulation, and reduced housing cost burdens for homeowners.',
  },
  ['owner_occupied_rate']: {
    labelQuestion: 'Why Is the Rate of Owner-Occupied Housing Significant?',
    prompt:
      'Discuss the significance of owner-occupied housing units in fostering community stability, wealth building, and long-term investment in neighborhoods.',
  },
  ['percent_homeless_individuals']: {
    labelQuestion: 'What Factors Influence Individual Homelessness?',
    prompt:
      "Analyze the factors that contribute to the proportion of homeless individuals compared to families. Include considerations such as economic instability, mental health challenges, social support networks, and local policies on housing and homelessness. Reference trends or insights from Polco's tools or relevant case studies where applicable.",
  },
  ['percent_homeless_in_families']: {
    labelQuestion:
      'How Does Family Homelessness Differ from Individual Homelessness?',
    prompt:
      'Analyze how family homelessness differs from individual homelessness in terms of contributing factors, support needs, and systemic challenges.',
  },
  ['subsidized_units_per_10000_pop']: {
    labelQuestion:
      'What Does the Number of Subsidized Housing Units Per 10,000 People Indicate?',
    prompt:
      "Explain the concept of 'Number of Subsidized Housing Units Per 10,000 People,' including how it is calculated and its significance in assessing housing affordability and accessibility in a community.",
  },
  ['vacant_housing_rate']: {
    labelQuestion: 'How Can We Reduce or Repurpose Vacant Housing Units?',
    prompt:
      'Suggest strategies to reduce housing vacancies or repurpose vacant units, including incentives for property redevelopment, affordable housing initiatives, and policies to prevent long-term vacancies.',
  },
  ['overall_homeless_rate_per_100k']: {
    labelQuestion: 'How Can Communities Reduce Homelessness?',
    prompt:
      'Suggest actionable strategies, programs, or initiatives that can reduce the Homelessness Rate. Reference specific examples or best practices from Polco case studies or successful interventions documented in related Polco tools or surveys.',
  },
  ['bric_econom_sub_index_score']: {
    labelQuestion: 'What Does Our BRIC Economic Score Mean?',
    prompt:
      "Analyze the meaning of our community's BRIC Economic/Financial Sub-Index score. Explain what the score reflects about our community's economic resilience, how it compares to benchmarks or similar communities, and what factors (e.g., employment stability, business diversity, access to capital) contribute to the score. Suggest areas for further analysis to enhance understanding of economic resilience.",
  },
  ['businesses_per_capita']: {
    labelQuestion: 'How Can We Attract More Businesses?',
    prompt:
      'Provide strategies to increase the number of businesses in our community. Focus on actions like offering incentives for startups, improving access to resources for entrepreneurs, streamlining business regulations, and enhancing infrastructure to support commercial growth. Include examples of successful initiatives from other communities.',
  },
  ['child_poverty_rate']: {
    labelQuestion: 'What Drives Child Poverty?',
    prompt:
      "Analyze the factors contributing to child poverty in our community. Consider variables like household income, access to affordable housing, employment opportunities for parents, and availability of social support programs. Highlight any trends or disparities that may impact children's well-being and suggest areas for further exploration.",
  },
  ['gdp_per_capita']: {
    labelQuestion: 'How Can We Boost GDP Per Capita?',
    prompt:
      'Suggest strategies to improve GDP per capita in our community. Consider approaches like fostering innovation, attracting high-value industries, investing in workforce development, and improving infrastructure. Include examples of initiatives from other communities that have successfully increased economic productivity and prosperity.',
  },
  ['poverty_rate']: {
    labelQuestion: 'What Drives Poverty?',
    prompt:
      'Analyze the factors contributing to the poverty rate among residents in our community. Consider variables like employment opportunities, housing costs, access to education, and availability of social services. Highlight any disparities or trends and suggest areas where further analysis could uncover actionable insights.',
  },
  ['ssi_recipients_18_to_64_per_100k']: {
    labelQuestion: 'How Can We Support Adult SSI Recipients?',
    prompt:
      'Provide strategies to better support SSI recipients aged 18 to 64 in our community. Focus on initiatives like enhancing access to job training programs, improving healthcare support, offering financial literacy education, and expanding outreach programs for eligible individuals. Include examples of successful initiatives from other communities.',
  },
  ['ssi_recipients_65_or_older_per_100k']: {
    labelQuestion: 'How Can We Better Support Older SSI Recipients?',
    prompt:
      'Recommend strategies to support SSI recipients aged 65 and older in our community. Focus on initiatives such as improving access to healthcare, enhancing social service outreach, addressing affordable housing needs, and creating programs to reduce financial stress among older adults. Provide examples of successful approaches from other communities.',
  },
  ['ssi_recipients_total_per_100k']: {
    labelQuestion: 'What Influences SSI Recipient Numbers?',
    prompt:
      'Explore the factors driving the total number of SSI recipients in our community. Consider demographic trends, economic conditions, healthcare access, and eligibility policies. Identify how these factors may have shifted over time and suggest areas for further analysis or data collection.',
  },
  ['ssi_recipients_under_18_per_100k']: {
    labelQuestion: 'How Can We Support Children on SSI?',
    prompt:
      'Provide strategies to better support SSI recipients under 18 in our community. Focus on initiatives like improving access to specialized healthcare, offering support services for families, ensuring education accommodations, and increasing awareness of SSI benefits and eligibility. Include examples of successful approaches from other communities.',
  },
  ['unemployment_rate']: {
    labelQuestion: 'How Can We Support Job Growth?',
    prompt:
      'Provide strategies to reduce unemployment in our community. Consider approaches like strengthening workforce readiness, targeting high-growth industries, improving access to job opportunities through transportation or infrastructure enhancements, and fostering public-private partnerships. Include suggestions for how these solutions could align with the broader economic goals of the community.',
  },
  ['childcare_centers']: {
    labelQuestion: 'How Can We Improve Child Care Access?',
    prompt:
      'Provide strategies to improve access to child care centers in our community. Focus on solutions such as expanding funding for child care subsidies, incentivizing child care center development in underserved areas, increasing workforce training for child care providers, and enhancing support for family-based care options. Include examples of successful initiatives from other communities.',
  },
  ['gender_pay_gap']: {
    labelQuestion: 'What Does This Ratio Tell Us?',
    prompt:
      "Analyze the ratio of women's to men's median earnings in our community. Explain what the ratio indicates about income equity between genders, how it compares to national benchmarks or similar communities, and what factors (e.g., industry composition, job roles, education levels) may influence this metric.",
  },
  ['gini']: {
    labelQuestion: 'What Is the Gini Score?',
    prompt:
      'Explain the Gini income equality coefficient. Describe what the score represents, how it is calculated, and what a lower or higher value indicates about income distribution within a community. Include how this metric can be used to identify areas for potential policy or program focus.',
  },
  ['wfh_rate']: {
    labelQuestion: 'How Can We Support Remote Workers?',
    prompt:
      'Provide strategies to better support residents working from home. Focus on initiatives like improving broadband infrastructure, creating remote work hubs, providing resources for remote work setup, and fostering local community engagement for remote employees. Include examples of successful initiatives from other communities.',
  },
  ['enrollment_k12']: {
    labelQuestion: 'Why Does Enrollment Matter?',
    prompt:
      "Explain why Total K-12 Student Enrollment is an important metric for the community. Highlight how it affects areas such as resource allocation, school funding, infrastructure planning, and student outcomes. Describe how enrollment trends interact with demographic changes, housing availability, and migration patterns, and suggest what this metric might indicate about the community's future needs.",
  },
  ['graduation_rate']: {
    labelQuestion: 'How Can We Increase Graduation Rates?',
    prompt:
      'Provide strategies to improve the high school graduation rate in our community. Focus on initiatives like early intervention for struggling students, mentorship programs, career and college readiness initiatives, and providing alternative pathways to graduation. Include examples of successful programs from other communities that have improved graduation outcomes.',
  },
  ['high_school_attainment']: {
    labelQuestion: 'How Can We Increase Graduation Rates?',
    prompt:
      'Provide strategies to improve the percentage of adults with a high school degree in our community. Focus on initiatives like expanding access to adult education programs, increasing support for at-risk students, enhancing career readiness initiatives, and addressing barriers to high school completion. Include examples of successful programs from other communities.',
  },
  ['library_circulation']: {
    labelQuestion: 'Why Is Library Circulation Important?',
    prompt:
      'Explain why total library circulation is a valuable metric for understanding community engagement and resource utilization. Highlight its connection to literacy, lifelong learning, and access to technology. Describe how this metric interacts with other factors like educational outcomes, digital equity, and community programming, and suggest how it might guide planning or resource allocation.',
  },
  ['library_visits_per_capita']: {
    labelQuestion: 'Why Does Library Visitation Matter?',
    prompt:
      'Explain why the library visitation rate is a valuable metric for our community. Highlight how it reflects levels of educational engagement, community resource utilization, and public access to technology. Describe how this metric interacts with broader factors like literacy rates, economic development, and inclusivity, and suggest what insights or decisions it might inform.',
  },
  ['passrate_all_math']: {
    labelQuestion: 'How Can We Improve Math Scores?',
    prompt:
      'Provide strategies to improve the percentage of students earning a passing score on state math assessments. Focus on initiatives such as implementing targeted interventions, enhancing STEM curriculum, increasing access to math tutoring, and supporting teacher professional development. Include examples of successful programs or approaches from other communities.',
  },
  ['passrate_all_reading']: {
    labelQuestion: 'How Can We Improve Reading Scores?',
    prompt:
      'Provide strategies to improve the percentage of students earning a passing score on state reading assessments. Focus on initiatives such as enhancing teacher training, implementing targeted literacy interventions, increasing access to reading resources, and engaging families in literacy development. Include examples of successful programs from other communities.',
  },
  ['passrate_hs_math']: {
    labelQuestion: 'Why Are High School Scores Different?',
    prompt:
      'Explain why the percentage of high school students earning a passing score on state assessments might differ from the overall K-12 passing score. Highlight factors such as curriculum complexity, developmental readiness, subject-specific challenges, resource allocation, and varying instructional approaches at different grade levels. Include insights on how these differences can inform educational strategies and interventions.',
  },
  ['passrate_hs_reading']: {
    labelQuestion: 'What Affects Reading Scores in High School?',
    prompt:
      'Analyze the factors influencing the percentage of high school students earning a passing score on state reading assessments. Consider variables such as curriculum effectiveness, teacher quality, literacy interventions, and socioeconomic factors. Highlight trends, disparities, or areas for further investigation to better understand this metric.',
  },
  ['school_funding_adequacy']: {
    labelQuestion: 'What Drives Spending Gaps in Schools?',
    prompt:
      'Analyze the factors contributing to public school spending gaps in our community. Consider variables such as local funding mechanisms, state and federal contributions, property tax disparities, and enrollment trends. Highlight any disparities between schools or districts and suggest areas for further investigation to better understand these gaps.',
  },
  ['school_segregation']: {
    labelQuestion: 'What Does Our School Diversity Index Show?',
    prompt:
      'Analyze the School Racial and Ethnic Distribution Index for our community. Explain what the index reflects about the diversity of the student population, how it compares to benchmarks or similar communities, and what factors (e.g., residential patterns, school policies, or demographic shifts) influence the distribution. Highlight trends or areas that may require further exploration.',
  },
  ['student_teacher_ratio_k12']: {
    labelQuestion: 'Why Does This Metric Matter?',
    prompt:
      'Explain why the K-12 student/teacher ratio is an important metric for understanding educational quality in our community. Highlight its connection to student outcomes, teacher workload, and classroom engagement. Describe how this ratio interacts with broader factors like funding, enrollment, and academic performance, and suggest how it might inform resource allocation or policy decisions.',
  },
  ['age_adjusted_death_rate']: {
    labelQuestion: 'What Can We Do to Prevent Premature Deaths?',
    prompt:
      'Provide strategies to reduce the premature mortality rate in our community. Focus on initiatives such as expanding access to preventive healthcare, promoting public health campaigns for chronic disease management, improving access to mental health and addiction services, and addressing socioeconomic inequities. Include examples of successful approaches from other communities.',
  },
  ['ambulatory_difficulty_pct']: {
    labelQuestion: 'Why Is Mobility Accessibility Important?',
    prompt:
      'Explain why the Ambulatory Difficulty metric is important and how it connects to broader issues like quality of life, transportation, and healthcare access.',
  },
  ['binge_drinking']: {
    labelQuestion: 'What Affects Binge Drinking Rates?',
    prompt:
      'Analyze the factors contributing to the percentage of Binge Drinking Among Adult Residents, including cultural norms, socioeconomic conditions, stress levels, and availability of alcohol. Identify trends or disparities that may require attention.',
  },
  ['chain_smoking']: {
    labelQuestion: 'How Can We Reduce Smoking Rates?',
    prompt:
      'Suggest strategies to reduce the percentage of Adult Residents Who Currently Smoke Cigarettes, focusing on expanding access to cessation programs, implementing public health campaigns, and enforcing tobacco control policies.',
  },
  ['child_mortality_rate']: {
    labelQuestion: 'How Can We Prevent Early Deaths in Youth?',
    prompt:
      'Provide strategies to reduce the Child and Teen Mortality Rate in our community. Focus on initiatives like enhancing access to pediatric healthcare, implementing injury prevention programs, supporting mental health and suicide prevention efforts, and addressing social determinants of health. Include examples of successful approaches from other communities that have reduced child and teen mortality rates.',
  },
  ['cognitive_difficulty_pct']: {
    labelQuestion: 'How Can We Address Cognitive Support?',
    prompt:
      'Suggest strategies to improve resources, accommodations, and support for residents reflected in the Cognitive Difficulty metric.',
  },
  ['dental_visit']: {
    labelQuestion: 'Why Are Dental Visits Important?',
    prompt:
      'Explain why the metric for Dental Visits Among Adult Residents is important and how it connects to broader issues like oral health, overall health outcomes, and healthcare access.',
  },
  ['dentists']: {
    labelQuestion: 'How Can We Improve Access to Dental Care?',
    prompt:
      'Provide strategies to improve access to dentists in our community. Focus on initiatives like offering incentives for dentists to work in underserved areas, expanding dental health programs in schools, increasing access to affordable care, and supporting mobile or community dental clinics. Include examples of successful programs from other communities.',
  },
  ['diabetes_prevalence']: {
    labelQuestion: 'How Can We Address Diabetes in Our Community?',
    prompt:
      'Provide strategies to address the adult diabetes rate in our community. Focus on initiatives like increasing access to preventive care, promoting public health campaigns about nutrition and exercise, expanding chronic disease management programs, and improving access to healthy foods. Include examples of successful programs from other communities.',
  },
  ['disability_with_health_coverage_pct']: {
    labelQuestion: 'How Can We Address Insurance Gaps?',
    prompt:
      'Provide strategies to improve health insurance coverage for residents with disabilities in our community. Focus on initiatives like expanding Medicaid eligibility, increasing awareness of coverage options, enhancing outreach programs for underinsured individuals, and addressing accessibility barriers in the healthcare system. Include examples of successful efforts from other communities to close coverage gaps for residents with disabilities.',
  },
  ['doctor_checkup']: {
    labelQuestion: 'Why Are Routine Checkups Important?',
    prompt:
      'Explain why the metric for Routine Doctor Checkups Among Adult Residents is important and how it connects to broader issues like early disease detection, healthcare costs, and community health outcomes.',
  },
  ['food_insecurity_rate']: {
    labelQuestion: 'How Can We Reduce Food Insecurity?',
    prompt:
      'Suggest strategies to lower the Food Insecurity Rate in the community, focusing on expanding access to food assistance programs, increasing affordability of healthy foods, and fostering community partnerships to address gaps in food distribution.',
  },
  ['healthy_food_access_index']: {
    labelQuestion: 'What Affects Access to Healthy Foods?',
    prompt:
      'Analyze the factors influencing the Access to Healthy Foods Index in our community. Consider variables such as the availability of grocery stores, farmers markets, transportation options, and economic barriers. Highlight trends or disparities that may impact access to healthy foods and suggest areas for further exploration.',
  },
  ['hearing_difficulty_pct']: {
    labelQuestion: 'Why Is Hearing Accessibility Important?',
    prompt:
      'Explain why the Hearing Difficulty metric is important and how it relates to broader issues like quality of life, healthcare access, and social inclusion.',
  },
  ['hpsa_score']: {
    labelQuestion: 'What Drives Healthcare Shortages?',
    prompt:
      'Analyze the factors contributing to the severity of healthcare professional shortages in our community. Consider variables such as workforce training pipelines, geographic distribution, provider burnout, population growth, and policy barriers. Highlight trends or disparities that may exacerbate shortages and suggest areas for further exploration.',
  },
  ['independent_living_difficulty_pct']: {
    labelQuestion: 'What Is Independent Living Difficulty?',
    prompt:
      'Explain what the Independent Living Difficulty metric represents and its significance in understanding challenges residents face in performing daily activities and maintaining self-sufficiency in the community.',
  },
  ['infant_mortality_rate']: {
    labelQuestion: 'What Factors Drive Infant Mortality?',
    prompt:
      'Analyze the factors contributing to the Infant Mortality Rate in our community. Consider variables such as access to prenatal and postnatal care, maternal health, socioeconomic conditions, and environmental risks. Highlight trends, disparities, or key risk factors that impact infant mortality and suggest areas for further investigation.',
  },
  ['insufficient_sleep']: {
    labelQuestion: 'Why Does Sleep Health Matter?',
    prompt:
      'Explain why the percentage of adults who report fewer than 7 hours of sleep is an important metric for understanding community health. Highlight its connection to chronic disease risk, mental health, workplace productivity, and overall quality of life. Describe how this metric interacts with broader factors like public health initiatives, socioeconomic conditions, and access to healthcare, and suggest how it might inform community wellness strategies.',
  },
  ['lack_health_insurance']: {
    labelQuestion: 'How Can We Address Health Insurance Gaps?',
    prompt:
      'Suggest strategies to reduce the percentage of Adult Residents Without Current Health Insurance, focusing on increasing enrollment outreach, expanding eligibility programs, and supporting healthcare affordability initiatives.',
  },
  ['life_expectancy']: {
    labelQuestion: 'How Can We Increase Life Expectancy?',
    prompt:
      'Provide strategies to improve average life expectancy in our community. Focus on initiatives such as expanding access to preventive care, addressing socioeconomic inequities, promoting public health campaigns for healthy behaviors, and improving environmental conditions. Include examples of successful approaches from other communities that have extended life expectancy.',
  },
  ['mental_health_providers']: {
    labelQuestion: 'How Can We Improve Access to Mental Health Services?',
    prompt:
      'Provide strategies to improve access to mental health providers in our community. Focus on initiatives like increasing funding for mental health programs, incentivizing providers to work in underserved areas, expanding telehealth options, and raising community awareness to reduce stigma. Include examples of successful initiatives from other communities.',
  },
  ['preventable_hospital_stays']: {
    labelQuestion: 'What Drives Preventable Hospital Admissions?',
    prompt:
      'Analyze the factors contributing to the rate of preventable hospital admissions in our community. Consider variables such as access to primary care, prevalence of chronic conditions, healthcare literacy, and socioeconomic barriers. Highlight trends or disparities in this metric and suggest areas for further investigation to better understand its root causes.',
  },
  ['primary_care_physicians']: {
    labelQuestion: 'Why Does Access to Primary Care Matter?',
    prompt:
      'Explain why the availability of primary care physicians is a critical metric for understanding community health. Highlight its connection to preventive care, chronic disease management, and healthcare cost reduction. Describe how this metric interacts with broader factors like public health outcomes, insurance coverage, and population growth, and suggest how it might inform planning and policy decisions.',
  },
  ['self_care_difficulty_pct']: {
    labelQuestion: 'How Can We Improve Support for Self-Care?',
    prompt:
      'Suggest strategies to improve resources, accessibility, and support for residents reflected in the Self-Care Difficulty metric, focusing on in-home care services, community programs, and assistive technologies.',
  },
  ['snap_benefits']: {
    labelQuestion: 'Why Is SNAP Usage Important?',
    prompt:
      'Explain why this metric is important and how it connects to broader issues like poverty, food security, and public health.',
  },
  ['suicide_rate']: {
    labelQuestion: 'How Can We Reduce Suicide Rates?',
    prompt:
      'Provide strategies to reduce the suicide rate in our community. Focus on actions such as increasing access to mental health resources, implementing community support programs, training first responders and educators to recognize warning signs, and fostering public awareness campaigns. Include examples of successful initiatives from other communities that have addressed this issue effectively.',
  },
  ['teen_births']: {
    labelQuestion: 'How Can We Support Teens and Families?',
    prompt:
      'Provide strategies to support teens and families in our community in relation to teen births. Focus on initiatives like increasing access to healthcare, expanding educational resources, and fostering supportive community programs. Include examples of approaches from other communities that have effectively addressed this issue in a way that aligns with local needs and values.',
  },
  ['vision_difficulty_pct']: {
    labelQuestion: 'How Can We Improve Support for Vision Impairments?',
    prompt:
      'Suggest strategies to improve resources, accessibility, and support for residents reflected in the Vision Difficulty metric, focusing on healthcare services, assistive technology, and public accommodations.',
  },
  ['years_of_potential_life_lost_rate']: {
    labelQuestion: 'Why Is YPLL Important if I Know Premature Mortality?',
    prompt:
      'Explain why Years of Potential Life Lost (YPLL) Before Age 75 is a valuable metric even if the Premature Mortality Rate is already known. Highlight how YPLL provides additional insights by emphasizing the cumulative impact of premature deaths on lost years of life, with greater weight given to deaths at younger ages. Contrast this with Premature Mortality, which measures the frequency of deaths before age 75 without accounting for the age of the deceased. Describe how YPLL complements Premature Mortality by helping prioritize interventions that address the most impactful causes of early death and inform resource allocation decisions.',
  },
  ['social_vulnerability_index']: {
    labelQuestion: 'What Is the Social Vulnerability Index?',
    prompt:
      "Explain what the Social Vulnerability Index (SVI) represents and how it is calculated. Define its purpose in assessing a community's ability to prepare for, respond to, and recover from emergencies. Highlight how it differs from related metrics, such as economic resilience indices, and describe its role in guiding resource allocation and disaster planning.",
  },
  ['mental_health']: {
    labelQuestion: 'How Can We Improve Mental Health?',
    prompt:
      'Suggest strategies to reduce the percentage of Adult Residents with Less Than Good Mental Health, focusing on expanding access to mental health services, promoting awareness and education, and addressing underlying social determinants of health.',
  },
  ['no_leisure_time_physical_activity']: {
    labelQuestion: 'Why Is Physical Activity Important?',
    prompt:
      'Explain why the metric for Lack of Leisure-Time Physical Activity Among Adult Residents is important and how it connects to broader issues like chronic disease prevention, mental health, and community well-being.',
  },
  ['obesity']: {
    labelQuestion: 'How Can We Address Obesity?',
    prompt:
      'Suggest strategies to reduce the percentage of Obesity Among Adult Residents, focusing on promoting public health campaigns, improving access to healthy foods, enhancing community fitness programs, and addressing underlying social determinants of health.',
  },
  ['pct_access_to_exercise_opportunities']: {
    labelQuestion: 'How Can We Enhance Access?',
    prompt:
      'Provide strategies to improve access to exercise opportunities in our community. Focus on initiatives like creating new parks and recreational facilities, enhancing walkability and bikeability, reducing fees for gym memberships, and promoting community fitness programs. Include examples of successful programs from other communities that have expanded access to exercise.',
  },
  ['physical_health']: {
    labelQuestion: 'How Can We Improve Physical Health?',
    prompt:
      'Suggest strategies to reduce the percentage of Adult Residents with Less Than Good Physical Health, focusing on promoting preventive care, increasing access to fitness and nutrition resources, and addressing barriers to healthcare.',
  },
  ['alcohol_impaired_driving_deaths']: {
    labelQuestion: 'How Can We Reduce Alcohol-Related Driving Fatalities?',
    prompt:
      'Provide strategies to reduce alcohol-related driving fatalities in our community. Focus on initiatives such as increasing law enforcement visibility, implementing public awareness campaigns, expanding access to safe transportation alternatives like rideshares, and supporting programs to address alcohol misuse. Include examples of successful approaches from other communities that have effectively reduced these incidents.',
  },
  ['aian_pct']: {
    labelQuestion:
      'How Can Communities Support American Indian and Alaska Native Residents?',
    prompt:
      'Propose strategies to support American Indian and Alaska Native residents, including cultural preservation programs, equitable access to healthcare and education, and initiatives that honor tribal sovereignty and traditions.',
  },
  ['asian_pct']: {
    labelQuestion: 'How Can Communities Support Asian Residents?',
    prompt:
      'Propose strategies to support Asian residents, focusing on equitable access to resources like education, healthcare, and housing, as well as opportunities for cultural preservation and community engagement.',
  },
  ['attended_public_meeting']: {
    labelQuestion: 'How is attendance at public meetings measured?',
    prompt:
      'Explain how the attendance of adult residents at public meetings is measured. Include common methods, data sources, and tools used to assess participation levels and trends over time.',
  },
  ['belonged_groups_orgs']: {
    labelQuestion: 'Why Is Understanding Group Membership Important?',
    prompt:
      'Discuss the importance of understanding the percentage of adult residents involved in groups or organizations and its implications for building social networks, strengthening civic engagement, and enhancing community well-being.',
  },
  ['black_pct']: {
    labelQuestion:
      'How Can Communities Support Black or African American Residents?',
    prompt:
      'Propose strategies to support Black or African American residents, including equitable access to education, healthcare, housing, and opportunities for cultural expression and civic engagement.',
  },
  ['bric_comm_capital_sub_index_score']: {
    labelQuestion:
      "What influences a community's BRIC Community Capacity sub-index score?",
    prompt:
      "Analyze the factors that contribute to a community's score on the BRIC Community Capacity sub-index. Discuss the roles of education, governance, leadership, and access to resources in shaping this score.",
  },
  ['bric_social_sub_index_score']: {
    labelQuestion:
      'What does the BRIC Human Well-Being/Cultural/Social sub-index measure?',
    prompt:
      'Explain what the BRIC Human Well-Being/Cultural/Social sub-index (0 to 1) measures. Describe its components, how it reflects community resilience, and why it is significant for understanding the well-being and social structure of a community.',
  },
  ['census_participation']: {
    labelQuestion: 'Why Is Census Self-Response Percentage Important?',
    prompt:
      'Discuss the importance of the census self-response percentage in ensuring accurate population counts and its impact on federal funding, political representation, and community planning.',
  },
  ['collaborated_neighbors']: {
    labelQuestion:
      'How can we encourage more collaboration among neighbors to improve their community?',
    prompt:
      'Propose strategies, programs, or initiatives to foster collaboration among adult residents for community improvement. Highlight examples of successful community-driven projects or approaches that have strengthened neighborhood ties.',
  },
  ['community_resilience_score']: {
    labelQuestion: 'How Can Communities Increase Their Resilience Score?',
    prompt:
      'Propose strategies to improve the Community Resilience Score, focusing on enhancing infrastructure, fostering social networks, improving disaster preparedness, and investing in equitable resource distribution.',
  },
  ['contacted_visited_public_official']: {
    labelQuestion: 'Why does contacting or visiting public officials matter?',
    prompt:
      'Discuss the importance of adult residents contacting or visiting public officials and its impact on democratic processes, community representation, and the development of responsive public policies.',
  },
  ['conversation_spent_time_neighbors']: {
    labelQuestion:
      'What does it mean for adult residents to have a conversation or spend time with their neighbors?',
    prompt:
      "Explain the behavior of 'adult residents having a conversation or spending time with their neighbors.' Describe what this includes, how it is observed or measured, and its importance in understanding social cohesion and community interaction.",
  },
  ['disability_pct']: {
    labelQuestion:
      'What Factors Influence the Number of Residents with Disabilities?',
    prompt:
      'Analyze the factors that contribute to the prevalence of disabilities in a community, such as aging populations, access to healthcare, socioeconomic conditions, and historical trends in public health.',
  },
  ['discussed_issues_friends_family']: {
    labelQuestion: 'Why Is This Important?',
    prompt:
      'Explain why discussions about political, societal, or local issues among adult residents matter for community and civic health.',
  },
  ['discussed_issues_neighbors']: {
    labelQuestion:
      'Why does discussing political, societal, or local issues with neighbors matter?',
    prompt:
      'Discuss the importance of adult residents discussing political, societal, or local issues with their neighbors and its impact on community trust, civic participation, and collective problem-solving.',
  },
  ['disssimilarity_white_black']: {
    labelQuestion: 'What Is the White to Black Geographic Segregation Index?',
    prompt:
      "Explain the concept of 'White to Black Geographic Segregation,' including how it is measured on a scale from 0 to 100 and what the values indicate about the degree of residential separation between White and Black populations in a community.",
  },
  ['disssimilarity_white_nonwhite']: {
    labelQuestion: 'What Is the White to Non-White Geographic Segregation Index?',
    prompt:
      "Explain the concept of 'White to Non-White Geographic Segregation,' including its definition, how it is measured on a scale from 0 to 100, and what the score indicates about residential patterns and diversity within a community.",
  },
  ['donated_charity']: {
    labelQuestion:
      'What influences residents to donate to charities or non-political organizations?',
    prompt:
      'Analyze the factors that drive or hinder adult residents from donating to charities or non-political organizations. Consider social, economic, and cultural influences as well as trends in philanthropy and civic engagement.',
  },
  ['donated_political_org']: {
    labelQuestion:
      'Why does donating to political organizations or campaigns matter?',
    prompt:
      'Discuss the importance of donations to political organizations, parties, or campaigns by adult residents and their impact on political participation, representation, and democratic processes.',
  },
  ['exchanged_favors_neighbor']: {
    labelQuestion: 'Why does exchanging favors with neighbors matter?',
    prompt:
      'Discuss the importance of favor exchanges among neighbors and their impact on community trust, resilience, and social cohesion.',
  },
  ['female_pct']: {
    labelQuestion: 'Why Is Understanding the Female Population Important?',
    prompt:
      'Discuss the importance of understanding the percentage of female residents in shaping inclusive policies, addressing gender disparities, and fostering community well-being and equity.',
  },
  ['foreign_born_pct']: {
    labelQuestion: 'Why Is the Foreign-Born Population Important to Communities?',
    prompt:
      'Discuss the importance of understanding the foreign-born population and its contributions to community diversity, economic growth, and cultural enrichment. Highlight its relevance to public policy and resource planning.',
  },
  ['foreign_language_primary']: {
    labelQuestion: 'Why Is Understanding Language Diversity Important?',
    prompt:
      'Discuss the importance of understanding the prevalence of residents who speak a primary language other than English. Highlight its implications for inclusivity, equitable access to resources, and fostering community cohesion.',
  },
  ['hispanic_pct']: {
    labelQuestion: 'How Can Communities Support Hispanic or Latino Residents?',
    prompt:
      'Propose strategies to support Hispanic or Latino residents, focusing on culturally relevant services, equitable access to resources, representation in governance, and support for community-building initiatives.',
  },
  ['households_with_children']: {
    labelQuestion: 'Why Are Households with Children Significant?',
    prompt:
      'Discuss the importance of understanding the percentage of households with children in shaping community planning, education systems, and family-focused policies to support their needs and foster growth.',
  },
  ['interacted_different_race_background']: {
    labelQuestion:
      'What influences residents to interact with someone from a different racial, ethnic, or cultural background?',
    prompt:
      'Analyze the factors that encourage or hinder adult residents from interacting with individuals of different racial, ethnic, or cultural backgrounds. Consider the roles of community diversity, social norms, access to inclusive spaces, and personal openness.',
  },
  ['nhopi_pct']: {
    labelQuestion:
      'How Can Communities Support Native Hawaiian and Pacific Islander Residents?',
    prompt:
      'Propose strategies to support Native Hawaiian and Other Pacific Islander residents, including cultural preservation programs, equitable access to housing and healthcare, and community engagement initiatives.',
  },
  ['older_adult_pct']: {
    labelQuestion: 'How Can Communities Support Older Adults?',
    prompt:
      'Propose strategies to support older adults, focusing on healthcare services, affordable housing, community engagement programs, and transportation solutions tailored to their needs.',
  },
  ['other_two_or_more_pct']: {
    labelQuestion: 'What Does this Metric Represent?',
    prompt:
      "Explain the concept of 'Percent of Residents Who Are Some Other Race Alone or Two or More Races,' including how it is measured and its significance in capturing the complexity of racial and ethnic diversity within a community.",
  },
  ['percent_adult_residents_registered']: {
    labelQuestion: 'How Can Communities Increase Voter Registration?',
    prompt:
      'Propose strategies to improve voter registration rates, including implementing online and same-day registration, outreach campaigns targeting underrepresented groups, and simplifying the registration process.',
  },
  ['percent_voters_voted']: {
    labelQuestion:
      'How Do Registered Voter Turnout and Total Counted Ballots Differ?',
    prompt:
      "Explain the difference between 'Registered Voters Who Voted' and 'Total Voters with Counted Ballots in the Last National Election.' Highlight how the former focuses on registered voters as a subset and the latter includes all voters, including those registered on Election Day or through other methods.",
  },
  ['poc_pct']: {
    labelQuestion:
      "How Can Communities Use 'Persons of Color' and Individual Demographics Together?",
    prompt:
      "Propose strategies for leveraging both the 'Persons of Color' metric and individual demographics. Include examples of when to use aggregate data for equity planning and when to rely on granular demographics for targeted policy interventions.",
  },
  ['pop18_64']: {
    labelQuestion: 'Why Is the 18 to 64 Age Group Significant?',
    prompt:
      'Discuss the importance of understanding the number of residents aged 18 to 64 in shaping workforce policies, economic development strategies, and community planning to support this key demographic.',
  },
  ['pop_under_18']: {
    labelQuestion: 'Why Is Tracking the Under-18 Population Important?',
    prompt:
      'Discuss the importance of understanding the number of residents under 18 years of age and its implications for community planning, including education systems, childcare services, and future workforce development.',
  },
  ['provided_help_friends_family']: {
    labelQuestion:
      'What influences residents to provide support for friends or extended family?',
    prompt:
      'Analyze the factors that encourage or hinder adult residents from providing food, housing, money, or other forms of help to friends or extended family. Consider the roles of economic stability, cultural norms, and access to resources.',
  },
  ['registered_and_eligible_voters']: {
    labelQuestion: 'Why Is Tracking Voter Registration and Eligibility Important?',
    prompt:
      'Discuss the importance of understanding voter registration and eligibility as indicators of democratic participation, representation, and community engagement. Highlight its implications for policy-making and governance.',
  },
  ['regularly_consumed_news_issues']: {
    labelQuestion:
      'What does it mean for adult residents to regularly consume news or information about political, societal, or local issues?',
    prompt:
      "Explain the behavior of 'adult residents regularly consuming news or information about political, societal, or local issues.' Describe what it encompasses, how it is observed or measured, and its importance in fostering informed communities.",
  },
  ['shared_views_internet']: {
    labelQuestion:
      'Why does sharing views about political, societal, or local issues online matter?',
    prompt:
      'Discuss the importance of adult residents sharing their views about political, societal, or local issues on the internet or social media and its impact on public dialogue, civic engagement, and awareness.',
  },
  ['social_associations']: {
    labelQuestion: 'What Factors Influence the Number of Social Associations?',
    prompt:
      'Analyze the factors that affect the number of social associations, such as population density, economic conditions, cultural values, and availability of community spaces. Discuss trends that might influence their prevalence.',
  },
  ['time_with_friends_family']: {
    labelQuestion:
      'How can we encourage residents to strengthen connections with friends and family?',
    prompt:
      'Propose strategies, programs, or initiatives to help adult residents maintain regular interactions with friends and family. Highlight examples of tools or activities that foster stronger personal relationships.',
  },
  ['total_voters_last_election']: {
    labelQuestion: 'What Does Total Voters with Counted Ballots Represent?',
    prompt:
      "Explain the concept of 'Total Voters with Counted Ballots,' including how this metric is defined, how it is measured, and its significance for understanding voter participation in the last national election.",
  },
  ['veteran_pct']: {
    labelQuestion: 'How Can Communities Support Military Veterans?',
    prompt:
      'Propose strategies to support military veterans, focusing on access to healthcare, housing assistance, job training programs, and opportunities for community engagement and recognition.',
  },
  ['volunteered_org']: {
    labelQuestion: 'Why Is Understanding Volunteer Participation Important?',
    prompt:
      'Discuss the importance of understanding the percentage of adult residents who volunteer and its implications for building strong social networks, enhancing civic engagement, and addressing community needs.',
  },
  ['voted_last_election']: {
    labelQuestion: 'What Factors Influence Local Voter Turnout?',
    prompt:
      'Analyze the factors that affect local voter turnout, such as the visibility of local elections, voter registration processes, civic education, and community awareness of local government impact.',
  },
  ['voter_turnout']: {
    labelQuestion: 'How Can Communities Boost Local Voter Engagement?',
    prompt:
      'Propose strategies to increase local voter engagement, including voter education campaigns, outreach to underrepresented groups, improved voting accessibility, and initiatives to strengthen trust in local governance.',
  },
  ['white_pct']: {
    labelQuestion:
      'What Does the Percent of Residents Who Are White Alone Represent?',
    prompt:
      "Explain the concept of 'Percent of Residents Who Are White Alone,' including how it is measured, its definition, and its significance in understanding the demographic composition of a community.",
  },
  ['expected_annual_loss_score']: {
    labelQuestion: 'What Is the Expected Annual Loss Score?',
    prompt:
      "Explain the concept of 'Expected Annual Loss Score,' including its definition, how it is measured on a scale of 0 to 100, and what it represents about a community's risk of economic or structural loss due to disasters or hazards.",
  },
  ['mean_travel_time']: {
    labelQuestion: 'Why does average travel time to work matter?',
    prompt:
      'Discuss the importance of measuring average travel time to work. Explain its implications for employee well-being, productivity, environmental sustainability, and urban development.',
  },
  ['no_motor_vehicles_rate']: {
    labelQuestion:
      'Why does the percentage of households with no motor vehicle matter?',
    prompt:
      'Discuss the importance of measuring the percentage of households with no motor vehicle. Explain its implications for transportation planning, social equity, and community development.',
  },
  ['vehicles_per_household']: {
    labelQuestion: 'What influences the number of vehicles per household?',
    prompt:
      'Analyze the factors that determine the number of vehicles per household. Discuss the roles of household income, urban density, public transportation availability, and cultural preferences.',
  },
  ['walkability']: {
    labelQuestion: 'What does the walkability index score measure?',
    prompt:
      "Explain what the 'walkability index score (0 to 20)' measures. Describe what this score includes, how it is defined, and its significance in understanding urban accessibility, pedestrian infrastructure, and community design.",
  },
  ['long_commute_driving_alone']: {
    labelQuestion:
      'Why does the percentage of workers with long solo commutes matter?',
    prompt:
      'Discuss the significance of the percentage of workers with long solo commutes. Explain its impact on worker productivity, quality of life, environmental sustainability, and urban planning.',
  },
  ['work_transportation_bicycle']: {
    labelQuestion:
      'Why does the percentage of employed residents who bicycle to work matter?',
    prompt:
      'Discuss the importance of measuring the percentage of employed residents who bicycle to work. Explain its impact on health, environmental sustainability, traffic congestion, and community livability.',
  },
  ['work_transportation_carpooled']: {
    labelQuestion:
      'How can we increase the percentage of employed residents who carpool to work?',
    prompt:
      'Propose strategies, programs, or initiatives to encourage more employed residents to carpool to work. Include examples such as carpool matching platforms, employer-sponsored incentives, and high-occupancy vehicle (HOV) lane benefits.',
  },
  ['work_transportation_drove_alone']: {
    labelQuestion:
      'What influences the percentage of employed residents who drive alone to work?',
    prompt:
      'Analyze the factors that contribute to employed residents driving alone to work. Discuss the roles of urban planning, public transportation availability, job locations, and economic factors like gas prices and vehicle ownership.',
  },
  ['work_transportation_other']: {
    labelQuestion:
      'What does the percentage of employed residents using other means to get to work measure?',
    prompt:
      "Explain what the 'percentage of employed residents using other means to get to work' measures. Describe what this includes, how it is defined, and its significance in understanding alternative commuting behaviors such as walking, skateboarding, or ridesharing.",
  },
  ['work_transportation_public']: {
    labelQuestion:
      'What influences the percentage of employed residents using public transportation to get to work?',
    prompt:
      'Analyze the factors that affect the percentage of employed residents using public transportation to commute. Discuss the roles of public transit availability, affordability, urban density, and cultural attitudes.',
  },
  ['work_transportation_walked']: {
    labelQuestion:
      'Why does the percentage of employed residents who walk to work matter?',
    prompt:
      'Discuss the importance of measuring the percentage of employed residents who walk to work. Explain its implications for public health, environmental sustainability, and community livability.',
  },
  ['traffic_volume']: {
    labelQuestion: 'What does traffic volume measure?',
    prompt:
      "Explain what 'traffic volume' measures. Describe what this includes, how it is defined, and its importance in understanding transportation patterns, infrastructure use, and community mobility.",
  },
  ['fatal_mv_crash_cycle_ped_pct']: {
    labelQuestion:
      'How can we reduce fatal motor vehicle crashes involving cyclists or pedestrians?',
    prompt:
      'Propose strategies, programs, or initiatives to reduce fatal motor vehicle crashes involving cyclists or pedestrians. Include examples such as improving crosswalk and bike lane infrastructure, lowering speed limits, increasing driver education, and implementing traffic calming measures.',
  },
  ['fatal_mv_crash_rate']: {
    labelQuestion: 'What influences the number of fatal motor vehicle crashes?',
    prompt:
      'Analyze the factors that contribute to fatal motor vehicle crashes. Discuss the roles of road conditions, driver behavior, vehicle safety standards, weather, and enforcement of traffic laws.',
  },
  ['air_particulate_matter']: {
    labelQuestion: 'What influences the average daily density of PM2.5?',
    prompt:
      'Analyze the factors contributing to the average daily density of PM2.5 in the air. Discuss the roles of industrial emissions, vehicular traffic, natural events (e.g., wildfires), and urban density in shaping PM2.5 levels.',
  },
  ['bric_environment_sub_index_score']: {
    labelQuestion: 'What does the BRIC Environmental/Natural sub-index measure?',
    prompt:
      "Explain what the 'BRIC Environmental/Natural sub-index (0 to 1)' measures. Describe what this includes, how it is defined, and its significance in understanding community resilience based on environmental and natural factors.",
  },
  ['coastal_flooding_freq']: {
    labelQuestion: 'What influences the annualized frequency of coastal flooding?',
    prompt:
      'Analyze the factors contributing to the annualized frequency of coastal flooding. Discuss the roles of sea level rise, storm surge events, tidal cycles, and coastal development.',
  },
  ['coastal_flooding_risk']: {
    labelQuestion:
      "What influences a community's coastal flooding hazard risk score?",
    prompt:
      "Analyze the factors contributing to a community's coastal flooding hazard risk score. Discuss the roles of sea level rise, storm surge patterns, coastal development, and natural protective barriers like wetlands and mangroves.",
  },
  ['cold_wave_freq']: {
    labelQuestion: 'What does cold wave annualized frequency measure?',
    prompt:
      "Explain what 'cold wave annualized frequency' measures. Describe what this includes, how it is defined, and its significance in understanding the frequency of extreme cold events and their impacts on communities.",
  },
  ['cold_wave_risk']: {
    labelQuestion: 'What does the cold wave hazard risk score measure?',
    prompt:
      "Explain what the 'cold wave hazard type risk score (0 to 100)' measures. Describe what this includes, how it is defined, and its significance in understanding community vulnerability to extreme cold events and their potential impacts.",
  },
  ['drought_freq']: {
    labelQuestion: 'How can we mitigate the impacts of frequent droughts?',
    prompt:
      'Propose strategies, programs, or initiatives to mitigate the impacts of frequent droughts. Include examples such as improving water conservation, promoting drought-resistant crops, enhancing irrigation systems, and developing long-term water management plans.',
  },
  ['drought_risk']: {
    labelQuestion: 'What does the drought hazard risk score measure?',
    prompt:
      "Explain what the 'drought hazard type risk score (0 to 100)' measures. Describe what this includes, how it is defined, and its significance in understanding community vulnerability to drought conditions.",
  },
  ['flood_pct_100']: {
    labelQuestion:
      'What influences the percent of land area in the 100-year flood zone?',
    prompt:
      'Analyze the factors contributing to the percent of land area in the 100-year flood zone. Discuss the roles of hydrological patterns, land elevation, watershed management, and urban development.',
  },
  ['flood_sqm_100']: {
    labelQuestion: 'What does the land area in the 100-year flood zone measure?',
    prompt:
      "Explain what the 'square meters of land area in the 100-year flood zone' measures. Describe what this includes, how it is defined, and its significance in understanding flood risk, land use planning, and community vulnerability.",
  },
  ['heat_wave_freq']: {
    labelQuestion: 'What does heat wave annualized frequency measure?',
    prompt:
      "Explain what 'heat wave annualized frequency' measures. Describe what this includes, how it is defined, and its significance in understanding climate trends, public health risks, and energy demands.",
  },
  ['heat_wave_risk']: {
    labelQuestion: 'Why does the heat wave hazard risk score matter?',
    prompt:
      'Discuss the importance of measuring the heat wave hazard risk score. Explain its implications for public health, emergency preparedness, infrastructure resilience, and community adaptation to climate change.',
  },
  ['hurricane_freq']: {
    labelQuestion: 'How can we mitigate the impacts of frequent hurricanes?',
    prompt:
      'Propose strategies, programs, or initiatives to mitigate the impacts of frequent hurricanes. Include examples such as enhancing early warning systems, improving building resilience, restoring coastal ecosystems, and preparing emergency response plans.',
  },
  ['hurricane_risk']: {
    labelQuestion: "What influences a community's hurricane hazard risk score?",
    prompt:
      "Analyze the factors contributing to a community's hurricane hazard risk score. Discuss the roles of geographical location, historical hurricane frequency, building resilience, population density, and emergency preparedness.",
  },
  ['ice_storm_freq']: {
    labelQuestion: 'How can we mitigate the impacts of frequent ice storms?',
    prompt:
      'Propose strategies, programs, or initiatives to mitigate the impacts of frequent ice storms. Include examples such as improving road salting practices, hardening utility infrastructure, and developing early warning systems.',
  },
  ['ice_storm_risk']: {
    labelQuestion: "How can we reduce a community's ice storm hazard risk score?",
    prompt:
      "Propose strategies, programs, or initiatives to reduce a community's ice storm hazard risk score. Include examples such as strengthening utility infrastructure, improving road safety protocols, and enhancing community preparedness through early warning systems.",
  },
  ['lc_pct_barren']: {
    labelQuestion: 'Why does the extent of barren land cover matter?',
    prompt:
      'Discuss the importance of measuring barren land cover. Explain its implications for biodiversity, climate change mitigation, water management, and land productivity.',
  },
  ['lc_pct_cropland']: {
    labelQuestion: 'How can we manage cropland and pasture land cover sustainably?',
    prompt:
      'Propose strategies, programs, or initiatives to manage cropland and pasture areas sustainably. Include examples such as crop rotation, soil conservation practices, agroforestry, and livestock management systems.',
  },
  ['lc_pct_developed']: {
    labelQuestion: 'Why does developed land cover matter?',
    prompt:
      'Discuss the importance of measuring developed land cover. Explain its implications for environmental sustainability, resource management, urban livability, and economic development.',
  },
  ['lc_pct_forest']: {
    labelQuestion: 'Why does forest land cover matter?',
    prompt:
      'Discuss the importance of measuring forest land cover. Explain its implications for climate change mitigation, biodiversity conservation, and the provision of ecosystem services like water filtration and air purification.',
  },
  ['lc_pct_grassland']: {
    labelQuestion: 'Why does grassland (herbaceous) land cover matter?',
    prompt:
      'Discuss the importance of measuring grassland (herbaceous) land cover. Explain its implications for carbon storage, wildlife habitats, erosion prevention, and agricultural productivity.',
  },
  ['lc_pct_openwater']: {
    labelQuestion: 'Why does open water land cover matter?',
    prompt:
      'Discuss the importance of measuring open water land cover. Explain its implications for biodiversity, climate regulation, water supply, recreation, and economic activities such as fishing and tourism.',
  },
  ['lc_pct_shrubscrub']: {
    labelQuestion: 'Why does shrub or scrub land cover matter?',
    prompt:
      'Discuss the importance of measuring shrub or scrub land cover. Explain its implications for biodiversity, ecosystem services, water resource management, and climate resilience.',
  },
  ['lc_pct_snowice']: {
    labelQuestion:
      'Why does the percent of land covered by perennial snow and ice matter?',
    prompt:
      'Discuss the importance of measuring the percent of land covered by perennial snow and ice. Explain its implications for global water supplies, sea level rise, ecosystems, and climate regulation.',
  },
  ['lc_pct_wetlands']: {
    labelQuestion: 'Why does wetland land cover matter?',
    prompt:
      'Discuss the importance of measuring wetland land cover. Explain its implications for flood mitigation, carbon sequestration, water quality improvement, and wildlife habitat preservation.',
  },
  ['pct_area_impacted_half_meter_slr']: {
    labelQuestion:
      'What influences the area impacted by a half-meter sea level rise?',
    prompt:
      'Analyze the factors contributing to the percent of land area impacted by a half-meter sea level rise. Discuss the roles of coastal topography, tidal range, storm surge frequency, and climate-induced factors such as ice melt and thermal expansion.',
  },
  ['pct_area_impacted_one_meter_slr']: {
    labelQuestion:
      'What does the percent area impacted by one-meter sea level rise by 2100 measure?',
    prompt:
      "Explain what 'percent area impacted by one-meter sea level rise by year 2100' measures. Describe what this includes, how it is defined, and its significance in understanding long-term climate change impacts, coastal vulnerability, and land-use planning.",
  },
  ['riverine_flooding_freq']: {
    labelQuestion:
      'How can we reduce the frequency and impacts of riverine flooding?',
    prompt:
      'Propose strategies, programs, or initiatives to mitigate the frequency and impacts of riverine flooding. Include examples such as enhancing floodplain zoning, restoring wetlands, constructing levees, and implementing sustainable watershed management practices.',
  },
  ['riverine_flooding_risk']: {
    labelQuestion:
      "What influences a community's riverine flooding hazard risk score?",
    prompt:
      "Analyze the factors contributing to a community's riverine flooding hazard risk score. Discuss the roles of rainfall patterns, river basin characteristics, land use changes, climate variability, and floodplain management.",
  },
  ['tornado_freq']: {
    labelQuestion: 'How can we mitigate the impacts of frequent tornadoes?',
    prompt:
      'Propose strategies, programs, or initiatives to mitigate the impacts of frequent tornadoes. Include examples such as strengthening building codes, implementing community warning systems, and enhancing disaster response plans.',
  },
  ['tornado_risk']: {
    labelQuestion: "What influences a community's tornado hazard risk score?",
    prompt:
      "Analyze the factors contributing to a community's tornado hazard risk score. Discuss the roles of geographical location, historical tornado frequency, building codes, and emergency preparedness efforts.",
  },
  ['unhealthy_sensitive_days']: {
    labelQuestion:
      'What does the number of days unhealthy for sensitive groups measure?',
    prompt:
      "Explain what 'days unhealthy for sensitive groups' measures. Describe what this includes, how it is defined, and its significance in understanding air quality, public health, and environmental trends.",
  },
  ['risk_score']: {
    labelQuestion: 'What does the National Risk Index for natural hazards measure?',
    prompt:
      "Explain what the 'National Risk Index for natural hazards (0 to 100)' measures. Describe what this includes, how it is defined, and its significance in understanding community vulnerability, preparedness, and resilience to natural hazards.",
  },
  ['wildfire_freq']: {
    labelQuestion: 'How can we reduce wildfire annualized frequency?',
    prompt:
      'Propose strategies, programs, or initiatives to reduce wildfire annualized frequency. Include examples such as controlled burns, vegetation management, community preparedness programs, and policies addressing human-caused ignition sources.',
  },
  ['wildfire_risk']: {
    labelQuestion: "What influences a community's wildfire hazard risk score?",
    prompt:
      "Analyze the factors contributing to a community's wildfire hazard risk score. Discuss the roles of vegetation density, climate conditions, land management practices, human activities, and proximity to fire-prone areas.",
  },
  ['historic_mean_cooling_degree_days']: {
    labelQuestion: 'What does cooling degree days (historical mean) measure?',
    prompt:
      "Explain what 'cooling degree days (historical mean)' measures. Describe what this includes, how it is defined, and its significance in understanding climate trends, energy demand, and cooling requirements.",
  },
  ['historic_mean_heating_degree_days']: {
    labelQuestion: 'What does heating degree days (historical mean) measure?',
    prompt:
      "Explain what 'heating degree days (historical mean)' measures. Describe what this includes, how it is defined, and its significance in understanding climate patterns, energy demand, and heating requirements.",
  },
  ['length_trail_segments_per_capita']: {
    labelQuestion: 'What does the length of trail segments per capita measure?',
    prompt:
      "Explain what 'length of trail segments per capita' measures. Describe what this includes, how it is defined, and its significance in understanding equitable access to recreational and transportation infrastructure within a community.",
  },
  ['live_near_park']: {
    labelQuestion:
      'Why does the percentage of residents living within a half mile of a park matter?',
    prompt:
      'Discuss the importance of measuring the percentage of residents living within a half mile of a park. Explain its implications for public health, community engagement, environmental sustainability, and equitable access to recreational spaces.',
  },
  ['total_length_trail_segments']: {
    labelQuestion: 'Why does the total length of trail segments matter?',
    prompt:
      'Discuss the importance of measuring the total length of trail segments. Explain its implications for public health, promoting outdoor recreation, supporting environmental conservation, and enhancing active transportation networks.',
  },
  ['ems_call_incidents_per_100000']: {
    labelQuestion: 'What influences the frequency of rescue and EMS incidents?',
    prompt:
      'Analyze the factors contributing to rescue and EMS incidents. Discuss the roles of population demographics, healthcare access, community health trends, accident rates, and environmental hazards in shaping these calls.',
  },
  ['false_alarm_incidents_per_100000']: {
    labelQuestion: 'How can we reduce false alarm and false call incidents?',
    prompt:
      'Propose strategies, programs, or initiatives to reduce the frequency of false alarm and false call incidents. Include examples such as public education campaigns, upgrading alarm systems, implementing penalties for repeated false alarms, and enhancing system maintenance protocols.',
  },
  ['firearm_fatalities']: {
    labelQuestion: 'What influences the firearm fatality rate?',
    prompt:
      'Analyze the factors contributing to the firearm fatality rate. Discuss the roles of firearm availability, socioeconomic conditions, mental health resources, community violence, and law enforcement policies.',
  },
  ['home_fire_injury_risk_score']: {
    labelQuestion: "What influences a community's fire-related injury risk score?",
    prompt:
      "Analyze the factors contributing to a community's fire-related injury risk score. Discuss the roles of housing conditions, population density, access to fire safety equipment, community awareness, and emergency response times.",
  },
  ['home_fire_risk_score']: {
    labelQuestion: "What influences a community's home fire risk score?",
    prompt:
      "Analyze the factors contributing to a community's home fire risk score. Discuss the roles of housing age and materials, heating systems, population density, fire prevention measures, and socioeconomic conditions.",
  },
  ['homicides']: {
    labelQuestion: 'What influences the homicide incidence rate?',
    prompt:
      'Analyze the factors contributing to the homicide incidence rate. Discuss the roles of socioeconomic conditions, access to firearms, gang activity, mental health resources, law enforcement practices, and community cohesion.',
  },
  ['incarcerated_individuals']: {
    labelQuestion:
      'What influences the number of incarcerated people in correctional facilities?',
    prompt:
      'Analyze the factors contributing to the number of incarcerated people in correctional facilities. Discuss the roles of crime rates, sentencing laws, recidivism, socioeconomic conditions, and availability of rehabilitation programs.',
  },
  ['miscellaneous_fire_dept_incidents_per_100000']: {
    labelQuestion:
      'What influences the frequency of fire responses to other types of incidents?',
    prompt:
      'Analyze the factors contributing to fire department responses to other types of incidents. Discuss the roles of local infrastructure, community needs, emergency planning, and non-traditional hazards like hazardous materials, rescues, or severe weather events.',
  },
  ['outdoor_fire_incidents_per_100000']: {
    labelQuestion: 'What influences the frequency of outdoor fire incidents?',
    prompt:
      'Analyze the factors contributing to outdoor fire incidents. Discuss the roles of weather conditions, human activities, vegetation management, and proximity to urban areas in shaping the occurrence of these incidents.',
  },
  ['property_crime_clear_rate']: {
    labelQuestion: 'What influences the property crime clearance rate?',
    prompt:
      'Analyze the factors contributing to property crime clearance rates. Discuss the roles of investigative resources, technological tools, community cooperation, crime reporting, and law enforcement practices.',
  },
  ['property_crime_rate']: {
    labelQuestion: 'How can we reduce the property crime rate?',
    prompt:
      'Propose strategies, programs, or initiatives to reduce the property crime rate. Include examples such as improving neighborhood watch programs, enhancing law enforcement presence, addressing socioeconomic disparities, and increasing access to security technology.',
  },
  ['structure_and_mobile_fire_incidents_per_100000']: {
    labelQuestion: 'Why do structural fire incidents matter?',
    prompt:
      'Discuss the importance of measuring fire response to structural fire incidents. Explain its implications for public safety, property protection, community resilience, and fire prevention strategies.',
  },
  ['violent_crime_clear_rate']: {
    labelQuestion: 'What influences the violent crime clearance rate?',
    prompt:
      'Analyze the factors contributing to violent crime clearance rates. Discuss the roles of investigative resources, technological tools, community cooperation, nature of the crime, and law enforcement practices.',
  },
  ['violent_crime_rate']: {
    labelQuestion: 'How can we reduce the violent crime rate?',
    prompt:
      'Propose strategies, programs, or initiatives to reduce the violent crime rate. Include examples such as increasing community-based policing, enhancing youth outreach programs, addressing economic disparities, and investing in mental health resources.',
  },
  ['average_power_outage_duration']: {
    labelQuestion: 'How can we reduce the average power outage duration?',
    prompt:
      'Propose strategies, programs, or initiatives to reduce the average power outage duration. Include examples such as improving grid infrastructure, adopting smart grid technologies, enhancing crew response times, and increasing investments in backup power systems.',
  },
  ['average_power_outage_restoration_time']: {
    labelQuestion: 'What influences average restoration time?',
    prompt:
      'Analyze the factors contributing to average restoration time. Discuss the roles of the severity of outages, utility response protocols, workforce availability, grid complexity, and the impact of extreme weather events.',
  },
  ['commercial_electricity_rate']: {
    labelQuestion:
      'What does average kilowatt-hour pricing in the commercial electricity sector measure?',
    prompt:
      "Explain what 'average kilowatt-hour pricing in the commercial electricity sector' measures. Describe what this includes, how it is defined, and its significance in understanding energy costs, business operational expenses, and economic trends.",
  },
  ['computer_with_broadband_pct']: {
    labelQuestion: 'Why does access to a computer and broadband internet matter?',
    prompt:
      'Discuss the importance of measuring access to computers and broadband internet. Explain its implications for education, workforce development, healthcare access, civic participation, and bridging the digital divide.',
  },
  ['computer_with_broadband_pct_oa']: {
    labelQuestion:
      'Why does access to a computer and broadband internet for older adults matter?',
    prompt:
      'Discuss the importance of measuring access to computers and broadband internet among older adults. Explain its implications for healthcare access, social connectivity, lifelong learning, and reducing isolation among seniors.',
  },
  ['computer_with_internet_pct']: {
    labelQuestion:
      'What influences the percentage of residents with a computer and internet?',
    prompt:
      'Analyze the factors contributing to the percentage of residents with a computer and internet. Discuss the roles of income levels, broadband infrastructure, affordability of devices and services, and digital literacy.',
  },
  ['computer_with_internet_pct_oa']: {
    labelQuestion:
      'What does the percentage of older adults with a computer and internet measure?',
    prompt:
      "Explain what 'percentage of older adults with a computer and internet' measures. Describe what this includes, how it is defined, how it is different than 'computer with broadband internet' and its significance in understanding digital access and connectivity among older populations.",
  },
  ['drinking_water_contaminations']: {
    labelQuestion: 'What causes drinking water contaminations?',
    prompt:
      'Analyze the factors contributing to drinking water contaminations. Discuss the roles of industrial runoff, agricultural practices, aging water infrastructure, natural contaminants, and pollution from human activities.',
  },
  ['drinking_water_violations']: {
    labelQuestion: 'What causes drinking water violations?',
    prompt:
      'Analyze the factors contributing to drinking water violations. Discuss the roles of aging infrastructure, insufficient treatment processes, industrial or agricultural runoff, regulatory enforcement gaps, and natural contaminant sources.',
  },
  ['health_related_drinking_water_violations']: {
    labelQuestion:
      'What does the measure of health-related drinking water violations represent?',
    prompt:
      "Explain what 'health-related drinking water violations' measures. Describe what this includes, how it is defined, and its significance in understanding threats to public health caused by water quality issues.",
  },
  ['industrial_electricity_rate']: {
    labelQuestion:
      'What influences average kilowatt-hour pricing in the industrial electricity sector?',
    prompt:
      'Analyze the factors contributing to average kilowatt-hour pricing in the industrial electricity sector. Discuss the roles of energy production costs, fuel prices, regional supply and demand, government policies, and renewable energy integration.',
  },
  ['internet_access_score']: {
    labelQuestion: 'What does the Internet access index score measure?',
    prompt:
      "Explain what the 'Internet access index score (0 to 1)' measures. Describe what this includes, how it is defined, and its significance in understanding digital connectivity, equity, and accessibility in a community.",
  },
  ['major_drinking_water_violations']: {
    labelQuestion:
      'What does the measure of major drinking water violations represent?',
    prompt:
      "Explain what 'major drinking water violations' measures. Describe what this includes, how it is defined, and its significance in understanding severe threats to water quality, public health, and regulatory compliance.",
  },
  ['pct_cell_only_internet']: {
    labelQuestion:
      'What influences the percentage of households with internet access only through a cell phone?',
    prompt:
      'Analyze the factors contributing to the percentage of households with internet access only through a cell phone. Discuss the roles of affordability, accessibility to broadband, urban versus rural disparities, and socioeconomic conditions.',
  },
  ['pct_high_speed_internet']: {
    labelQuestion:
      'Why does access to high-speed internet service for households matter?',
    prompt:
      'Discuss the importance of measuring access to high-speed internet service for households. Explain its implications for education, telecommuting, healthcare access, economic opportunities, and reducing the digital divide.',
  },
  ['power_outages']: {
    labelQuestion: 'What influences the number of power outages?',
    prompt:
      'Analyze the factors contributing to the number of power outages. Discuss the roles of weather events, aging infrastructure, energy demand, maintenance practices, and cybersecurity threats to power grids.',
  },
  ['residential_electricity_rate']: {
    labelQuestion:
      'What influences average kilowatt-hour pricing in the residential electricity sector?',
    prompt:
      'Analyze the factors contributing to average kilowatt-hour pricing in the residential electricity sector. Discuss the roles of energy production and transmission costs, regional energy demand, government regulations, and the adoption of renewable energy sources.',
  },
  ['total_domestic_water_usage_per_capita']: {
    labelQuestion: 'What influences the per capita rate of domestic water use?',
    prompt:
      'Analyze the factors contributing to the per capita rate of domestic water use. Discuss the roles of household size, water usage habits, appliance efficiency, local climate, and water pricing in shaping this metric.',
  },
  ['total_water_usage_per_capita']: {
    labelQuestion: 'What does the per capita rate of total water use measure?',
    prompt:
      "Explain what 'per capita rate of total water use in gallons per day' measures. Describe what this includes, how it is defined, and its significance in understanding water consumption patterns and resource management.",
  },
  ['total_pop']: {
    labelQuestion: 'Why Is Total Population Significant?',
    prompt:
      'Discuss the importance of understanding total population and its impact on economic development, housing, public services, and long-term planning. Highlight its role in shaping policy and investment priorities.',
  },
  ['annual_growth_rate_pop']: {
    labelQuestion: 'How Can Communities Manage Population Growth or Decline?',
    prompt:
      'Propose strategies to manage the impacts of population growth or decline, focusing on areas like infrastructure development, housing supply, job creation, and public service planning.',
  },
  ['pov_ethrace_ratio']: {
    labelQuestion: 'What Is the Ratio of BIPOC to White Poverty Rates?',
    prompt:
      "Explain the concept of 'Ratio of BIPOC Persons' Poverty Rate to White Persons' Poverty Rate,' including how it is calculated, what it represents, and its importance in understanding racial disparities in economic conditions.",
  },
  ['child_care_cost_burden']: {
    labelQuestion: 'What Affects Child Care Affordability?',
    prompt:
      "Analyze the factors contributing to the affordability of child care in our community. Consider variables such as median household income, availability of subsidies, regulatory requirements, and operational costs for child care providers. Highlight trends or disparities that may affect families' ability to afford care and suggest areas for further exploration.",
  },
  ['median_household_income']: {
    labelQuestion: 'What Impacts Household Income?',
    prompt:
      'Analyze the factors influencing the median annual household income in our community. Consider variables like local industry wages, employment rates, education levels, and cost of living. Highlight any trends or disparities that might warrant further investigation.',
  },
  ['free_reduced_lunch']: {
    labelQuestion: 'What Does This Metric Tell Us?',
    prompt:
      'Analyze the percentage of children eligible for free or reduced-price school lunch in our community. Explain what this metric indicates about economic conditions and food security among families. Highlight factors such as household income, unemployment, and access to social programs that may contribute to this figure. Suggest areas for deeper exploration or data collection to understand trends or disparities.',
  },
  ['pov_sex_ratio']: {
    labelQuestion: 'How Can Communities Reduce Gender Disparities in Poverty?',
    prompt:
      'Propose strategies to reduce the disparity in poverty rates between women and men, focusing on policies like wage equity, support for single mothers, affordable childcare, and access to job training programs.',
  },
  ['purchasing_decision_based_political_views']: {
    labelQuestion:
      "What does it mean for adult residents to make purchasing decisions based on a company's political values or business practices?",
    prompt:
      "Explain the behavior of 'adult residents making purchasing decisions based on a company's political values or business practices.' Describe what this includes, how it is observed or measured, and its importance in understanding consumer behavior and civic expression.",
  },
  ['charge_total_natural_resources']: {
    labelQuestion: 'What Are Charges for Natural Resources Expenditures?',
    prompt:
      "Explain 'Charges for Natural Resources Expenditures,' including how governments collect revenue from the use or extraction of natural resources such as water, timber, and minerals. Describe how natural resources revenue data can be used to assess economic reliance on resource extraction, analyze sustainability policies, and forecast government revenues from resource-based industries.",
  },
  ['total_intergovernmental_revenue']: {
    labelQuestion: 'What Are Intergovernmental Revenues?',
    prompt:
      "Explain 'Intergovernmental Revenues,' including funds received from federal and state governments through grants, shared revenues, and aid programs. Discuss how these funds impact local government budgets.",
  },
  ['total_fire_protection_expenditure']: {
    labelQuestion: 'What Are Fire Protection Expenditures?',
    prompt:
      "Explain 'Fire Protection Expenditures,' including how local and state governments fund fire departments, emergency response services, and fire prevention programs. Describe how fire protection expenditure data can be used to assess emergency service funding, evaluate resource allocation for fire departments, and compare fire safety investments across jurisdictions.",
  },
  ['total_general_sales_tax']: {
    labelQuestion: 'How Can I Use General Sales Tax Revenue Data?',
    prompt:
      "Describe how general sales tax revenue data can be used to analyze consumer activity, economic trends, and a government's dependency on consumption-based taxes. Discuss how fluctuations in sales tax revenue impact budget planning.",
  },
  ['total_debt_outstanding']: {
    labelQuestion: 'What Is Total Debt Outstanding?',
    prompt:
      "Explain 'Total Debt Outstanding,' including how governments track all outstanding debt obligations, such as bonds and loans. Discuss why this metric is important for financial stability assessments in government finance.",
  },
  ['total_long_term_debt_outstanding']: {
    labelQuestion: 'What Is Long-Term Debt Outstanding?',
    prompt:
      "Explain 'Long-Term Debt Outstanding,' including how governments account for debts with repayment periods exceeding one year. Describe how long-term debt data can be used to assess financial sustainability, evaluate infrastructure financing, and plan for debt repayment strategies.",
  },
  ['total_beginning_long_term_debt_outstanding']: {
    labelQuestion: 'What Is Beginning Long-Term Debt Outstanding?',
    prompt:
      "Explain 'Beginning Long-Term Debt Outstanding,' including how it represents the total debt a government carried over from the previous fiscal year. Discuss its role in debt management and financial planning.",
  },
  ['total_unemployment_compensation_expenditure']: {
    labelQuestion: 'What Are Unemployment Compensation Expenditures?',
    prompt:
      "Explain 'Unemployment Compensation Expenditures,' including how governments allocate funds to support unemployment benefits and workforce assistance programs. Describe how unemployment compensation expenditure data can be used to analyze economic downturns, track government support for unemployed individuals, and evaluate workforce recovery efforts.",
  },
  ['total_health_expenditure']: {
    labelQuestion: 'What Are Health-Related Expenditures?',
    prompt:
      "Explain 'Health-Related Expenditures,' including how governments allocate funding for public health programs, hospitals, and medical assistance initiatives. Describe how health-related expenditure data can be used to evaluate healthcare accessibility, assess government investment in public health, and track spending trends across communities.",
  },
  ['total_education_expenditure']: {
    labelQuestion: 'What Are Education Expenditures?',
    prompt:
      "Explain 'Education Expenditures,' including how governments allocate funding for K-12 schools, higher education institutions, and public education initiatives. Describe how education expenditure data can be used to assess school funding adequacy, evaluate trends in education spending, and compare investments in different educational levels.",
  },
  ['total_expenditures']: {
    labelQuestion: 'How Do Governments Track Total Expenditures?',
    prompt:
      "Explain 'Total Expenditures' in government finance, including how spending across different categories is assessed. Discuss how expenditure trends influence budget decisions and financial planning.",
  },
  ['total_sales_tax']: {
    labelQuestion: 'How Can I Use Total Sales Tax Revenue Data?',
    prompt:
      'Describe how total sales tax revenue data can be used to track consumer spending trends, evaluate the impact of tax policy changes, and assess government reliance on sales tax for revenue generation.',
  },
  ['total_parks_and_recreation_expenditure']: {
    labelQuestion: 'How Are Parks and Recreation Services Funded?',
    prompt:
      "Explain 'Parks and Recreation' expenditures, including funding for public parks, recreational programs, and community events. Discuss how these investments benefit public health and quality of life.",
  },
  ['employee_retirement_cash_and_securities']: {
    labelQuestion: 'What Is Employee Retirement Cash and Securities?',
    prompt:
      "Explain 'Employee Retirement Cash and Securities,' including how governments manage pension funds and other retirement-related financial assets.",
  },
  ['short_term_debt_end_of_year']: {
    labelQuestion: 'What Is Short-Term Debt at Year End?',
    prompt:
      "Explain 'Short-Term Debt at Year End,' including how it represents government liabilities due within one year. Discuss its role in financial stability assessments. Describe how short-term debt data can be used to assess liquidity, track government borrowing trends, and evaluate fiscal sustainability.",
  },
  ['total_housing_and_community_expenditure']: {
    labelQuestion: 'What Are Housing and Community Expenditures?',
    prompt:
      "Explain 'Housing and Community Expenditures,' including how governments allocate funds for affordable housing programs, urban development, and community revitalization initiatives. Describe how housing and community expenditure data can be used to evaluate local investment in affordable housing, track urban development progress, and assess community well-being.",
  },
  ['total_revenue']: {
    labelQuestion: 'How Do Governments Assess Total Revenues?',
    prompt:
      "Explain 'Total Revenues' as a financial metric, including how different revenue streams combine to support government operations. Discuss how trends in revenue impact budgeting decisions.",
  },
  ['total_employee_retirement_revenue']: {
    labelQuestion: 'What Is Employee Retirement Revenue?',
    prompt:
      "Explain 'Employee Retirement Revenue,' including how governments generate income to support pension funds and retirement benefits for public employees. Discuss revenue sources such as employee contributions, employer contributions, and investment income.",
  },
  ['insurance_trust_cash_and_securities']: {
    labelQuestion: 'What Are Insurance Trust Cash and Securities?',
    prompt:
      "Explain 'Insurance Trust Cash and Securities,' including how governments manage funds set aside for public insurance programs such as unemployment compensation and workers' compensation. Discuss how these funds are structured and reported in financial documents.",
  },
  ['total_highway_expenditure']: {
    labelQuestion: 'How Can I Use Highway Expenditure Data?',
    prompt:
      'Describe how highway expenditure data can be used to assess infrastructure investment, track trends in transportation funding, and evaluate the impact of spending on road quality and economic development.',
  },
  ['total_income_taxes']: {
    labelQuestion: 'How Can I Use Income Tax Revenue Data?',
    prompt:
      'Describe how income tax revenue data can be used to analyze economic conditions, assess government reliance on earnings-based taxation, and project revenue fluctuations during economic booms or recessions.',
  },
  ['total_taxes']: {
    labelQuestion: 'How Can I Use Total Taxes Collected Data?',
    prompt:
      'Describe how total taxes collected data can be used to assess overall government revenue strength, compare tax structures across jurisdictions, and evaluate the sustainability of tax policies.',
  },
  ['total_natural_resources_expenditure']: {
    labelQuestion: 'What Are Natural Resources Expenditures?',
    prompt:
      "Explain 'Natural Resources Expenditures,' including how governments allocate funds for environmental conservation, land management, water resources, and sustainable development programs.",
  },
  ['total_cash_and_securities']: {
    labelQuestion: 'What Are Total Cash and Securities?',
    prompt:
      "Explain 'Total Cash and Securities,' including how governments report their liquid assets and investments. Discuss how this financial measure reflects a government's fiscal health and ability to cover obligations.",
  },
  ['total_utility_expenditure']: {
    labelQuestion: 'What Are Utility Expenditures?',
    prompt:
      "Explain 'Utility Expenditures,' including how governments allocate spending on essential public services such as water, electricity, gas, and waste management. Discuss how these costs impact budgeting and infrastructure planning.",
  },
  ['charge_total_education']: {
    labelQuestion: 'What Are Charges for Total Education Expenditures?',
    prompt:
      "Explain 'Charges for Total Education Expenditures,' including how governments generate revenue from public education services such as tuition, fees, and grants. Discuss how this data is tracked in financial reports.",
  },
  ['government_operating_expenditures']: {
    labelQuestion: 'What Are Operating Expenditures?',
    prompt:
      "Explain 'Operating Expenditures,' including how governments allocate funds for day-to-day expenses such as salaries, facility maintenance, and public services. Describe how operating expenditure data can be used to assess government efficiency, track spending trends, and evaluate budget priorities for essential services.",
  },
  ['total_public_welfare_expenditure']: {
    labelQuestion: 'What Are Public Welfare Expenditures?',
    prompt:
      "Explain 'Public Welfare Expenditures,' including how governments allocate funds for social services such as housing assistance, food programs, and income support for vulnerable populations. Discuss how these expenditures impact community well-being.",
  },
  ['total_long_term_debt_issued']: {
    labelQuestion: 'What Is Long-Term Debt Issued?',
    prompt:
      "Explain 'Long-Term Debt Issued,' including how governments raise capital through bonds and loans for major infrastructure projects and long-term investments. Discuss how this debt is recorded and managed in financial reporting",
  },
  ['employee_retirement_benefit_payments']: {
    labelQuestion: 'How Can I Use Employee Retirement Benefit Payment Data?',
    prompt:
      'Describe how employee retirement benefit payment data can be used to evaluate pension fund sustainability, track public sector retirement obligations, and assess long-term financial commitments.',
  },
  ['employee_retirement_total_securities']: {
    labelQuestion: 'What Are Employee Retirement Total Securities?',
    prompt:
      "Explain 'Employee Retirement Total Securities,' including how governments invest pension funds in various financial instruments to ensure long-term stability for retirees. Discuss how these securities contribute to pension fund performance.",
  },
  ['property_tax']: {
    labelQuestion: 'How Can I Use Property Tax Revenue Data?',
    prompt:
      'Describe how property tax revenue data can be used to assess economic growth, evaluate local government funding stability, and forecast future revenue trends based on property value fluctuations.',
  },
  ['total_police_protection_expenditure']: {
    labelQuestion: 'What Are Police Protection Expenditures?',
    prompt:
      "Explain 'Police Protection Expenditures,' including how governments allocate funds for law enforcement, crime prevention, and public safety initiatives. Describe how police protection expenditure data can be used to assess law enforcement funding levels, evaluate public safety priorities, and compare spending trends across jurisdictions.",
  },
  ['employee_retirement_cash_and_deposits']: {
    labelQuestion: 'What Is Employee Retirement Cash and Deposits?',
    prompt:
      "Explain 'Employee Retirement Cash and Deposits,' including how governments manage liquid assets within pension funds to ensure retirement benefit payments for public employees. Discuss how this data is tracked in financial reports.",
  },
  ['total_unemployment_revenue']: {
    labelQuestion: 'What Is Unemployment Revenue?',
    prompt:
      "Explain 'Unemployment Revenue,' including how governments collect funds related to unemployment insurance and workforce programs. Describe how unemployment revenue data can be used to assess the financial health of workforce programs, track economic downturns, and evaluate the sustainability of unemployment insurance funds.",
  },
  ['total_utility_revenue']: {
    labelQuestion: 'What Is Utility Revenue?',
    prompt:
      "Explain 'Utility Revenue,' including how governments collect funds from public utilities such as water, electricity, and sanitation services. Describe how utility revenue data can be used to assess the financial stability of public utility services, track infrastructure investments, and evaluate rate-setting policies for residents and businesses.",
  },
  ['total_employee_retirement_expenditure']: {
    labelQuestion: 'What Are Employee Retirement Expenditures?',
    prompt:
      "Explain 'Employee Retirement Expenditures,' including how governments allocate funds to pay pensions, healthcare, and other benefits for retired public employees. Discuss how this spending impacts long-term financial planning.",
  },
  ['unemployment_compensation_benefit_payments']: {
    labelQuestion: 'How Can I Use Unemployment Compensation Benefit Payment Data?',
    prompt:
      'Describe how unemployment compensation benefit payment data can be used to analyze economic conditions, track government support for unemployed individuals, and evaluate workforce recovery trends.',
  },
  ['total_interest_on_debt']: {
    labelQuestion: 'How Can I Use Interest Paid on Debt Data',
    prompt:
      'Describe how interest paid on debt data can be used to assess borrowing costs, evaluate the financial impact of debt management strategies, and analyze trends in government debt repayment.',
  },
};

function getTrackDomainOverviewPrompt(analyticsDomain: AnalyticsDomain): AiPrompt {
  const prompt = `Fully describe the ${domainLabels[analyticsDomain]} Overview section on the Track Overview Page. Include an explanation of its elements (index score, change metric, key data points, and NCS integration if applicable) and its purpose. Use the knowledge base to provide a clear and comprehensive overview for the user.`;
  return {
    labelQuestion: 'What is this?',
    prompt,
  };
}
