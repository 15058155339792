import { ActivationState } from 'core';
import React from 'react';
import { ContentUnavailableType } from '../content-unavailable';
import { VisualizationProps, Visualization } from '../visualization-picker';
import { VisualizationUnavailable } from '../visualization-picker/not-found';

export interface SavedDataPointProps
  extends Omit<VisualizationProps, 'saveVisualization'> {
  readonly showInactiveSavedDataPoints?: boolean;
  readonly savedDataPointState: ActivationState;
}

export const SavedDataPoint: React.FC<SavedDataPointProps> = (props) => {
  if (!props.trackVariable) {
    return (
      <VisualizationUnavailable
        label={props.label}
        type={ContentUnavailableType.NOT_FOUND}
      />
    );
  }
  if (
    !props.showInactiveSavedDataPoints &&
    props.savedDataPointState === ActivationState.INACTIVE
  ) {
    return (
      <VisualizationUnavailable
        label={props.label}
        type={ContentUnavailableType.UNAVAILABLE}
      />
    );
  }
  return <Visualization {...props} trackVariable={props.trackVariable} />;
};
SavedDataPoint.displayName = 'SavedDataPoint';
