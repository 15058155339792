import {
  AiAssistantConversation,
  AiAssistantMessage,
  AiAssistantMessageRole,
  AiAssistantMessageType,
  AiAssistantPublisherInteractions,
  AiAssistantQueryStatus,
  AiAssistantQueryStatusType,
  AiAssistantSessionStatusType,
} from 'client/admin/core/ai-assistant-session';
import * as Gql from 'client/shared/graphql-client/graphql-operations.g';
import { ApiDate } from 'core';

type GqlAiAssistantQueryStatus = NonNullable<
  Gql.AdminCurrentAiAssistantQueryStatus['currentAiAssistantSession']
>;

type GqlAiAssistantConversation = NonNullable<
  Gql.AdminCurrentAiAssistantSessionConversation['currentAiAssistantSession']
>;

type GqlAiAssistantInteractions = NonNullable<
  Gql.AdminPublishingEntityAiAssistantInteractions['openPublishingEntityById']
>;

type GqlAiAssistantMessages = NonNullable<
  GqlAiAssistantConversation['conversation']
>['messages'];

type GqlAiAssistantMessage = NonNullable<GqlAiAssistantMessages>[0];
export namespace AiAssistantSessionTx {
  export function aiAssistantQueryStatusGqlToClient(
    currentAiAssistantSession: GqlAiAssistantQueryStatus | null
  ): {
    readonly id: string;
    readonly status: AiAssistantQueryStatus | null;
  } | null {
    if (!currentAiAssistantSession) {
      return null;
    }
    return {
      id: currentAiAssistantSession.id,
      status: currentAiAssistantSession.queryStatus
        ? _aiAssistantQueryStatusGqlToClient(currentAiAssistantSession.queryStatus)
        : null,
    };
  }
  export function aiAssistantSessionConversationGqlToClient(
    currentAiAssistantSession: GqlAiAssistantConversation | null
  ): {
    readonly id: string;
    readonly conversation: AiAssistantConversation | null;
  } | null {
    if (!currentAiAssistantSession) {
      return null;
    }
    return {
      id: currentAiAssistantSession.id,
      conversation: _aiAssistantConversationGqlToClient(
        currentAiAssistantSession.conversation
      ),
    };
  }
  export function aiAssistantSessionStatusTypeGqlToClient(
    type: Gql.AiAssistantSessionStatusType
  ) {
    switch (type) {
      case Gql.AiAssistantSessionStatusType.ACTIVE:
        return AiAssistantSessionStatusType.ACTIVE;
    }
  }

  export function aiAssistantPublisherGqlToClient(
    publisherInteractions: GqlAiAssistantInteractions
  ): AiAssistantPublisherInteractions | null {
    if (!publisherInteractions.aiAssistantInteractions) {
      return null;
    }
    return {
      publishingEntityId: publisherInteractions.id,
      queryCount: publisherInteractions.aiAssistantInteractions.queryCount,
      sessionCount: publisherInteractions.aiAssistantInteractions.sessionCount,
    };
  }
}

function _aiAssistantQueryStatusGqlToClient(
  status: NonNullable<GqlAiAssistantQueryStatus['queryStatus']>
): AiAssistantQueryStatus {
  return {
    type: aiAssistantQueryStatusTypeGqlToClient(status.type),
    loadingText: status.loadingText ?? undefined,
    message: status.message ?? undefined,
  };
}

function aiAssistantQueryStatusTypeGqlToClient(
  type: Gql.AiAssistantQueryStatusType
) {
  switch (type) {
    case Gql.AiAssistantQueryStatusType.PROCESSING:
      return AiAssistantQueryStatusType.PROCESSING;
    case Gql.AiAssistantQueryStatusType.RESPONDED:
      return AiAssistantQueryStatusType.RESPONDED;
  }
}

function aiAssistantMessageRoleGqlToClient(type: Gql.AiAssistantMessageRole) {
  switch (type) {
    case Gql.AiAssistantMessageRole.ASSISTANT:
      return AiAssistantMessageRole.ASSISTANT;
    case Gql.AiAssistantMessageRole.USER:
      return AiAssistantMessageRole.USER;
    case Gql.AiAssistantMessageRole.SYSTEM:
      return AiAssistantMessageRole.SYSTEM;
  }
}

function aiAssistantMessageRoleClientToGql(
  type: AiAssistantMessageRole
): Gql.AiAssistantMessageRole {
  switch (type) {
    case AiAssistantMessageRole.ASSISTANT:
      return Gql.AiAssistantMessageRole.ASSISTANT;
    case AiAssistantMessageRole.USER:
      return Gql.AiAssistantMessageRole.USER;
    case AiAssistantMessageRole.SYSTEM:
      return Gql.AiAssistantMessageRole.SYSTEM;
  }
}

function _aiAssistantConversationGqlToClient(
  conversation: GqlAiAssistantConversation['conversation'] | null
): AiAssistantConversation | null {
  if (!conversation) {
    return null;
  }
  return {
    modelVersion: conversation.modelVersion ?? undefined,
    userId: conversation.userId ?? undefined,
    publishingEntityId: conversation.publishingEntityId ?? undefined,
    messages: aiAssistantMessagesGqlToClient(conversation.messages),
  };
}

function aiAssistantMessagesGqlToClient(
  messages: GqlAiAssistantMessages | null
): readonly AiAssistantMessage[] {
  if (!messages) {
    return [];
  }

  return messages.map(({ role, content, timestamp }) => {
    return {
      role: aiAssistantMessageRoleGqlToClient(role),
      content,
      timestamp: ApiDate.fromApi(timestamp),
      type: AiAssistantMessageType.SINGLE,
    };
  });
}

export function aiAssistantMessagesClientToGql(
  messages: readonly AiAssistantMessage[]
): NonNullable<GqlAiAssistantMessages> {
  return messages.map(aiAssistantMessageClientToGql);
}

export function aiAssistantMessageClientToGql(
  message: AiAssistantMessage
): GqlAiAssistantMessage {
  const { role, content, timestamp } = message;
  return {
    __typename: 'AiAssistantMessage',
    role: aiAssistantMessageRoleClientToGql(role),
    content,
    timestamp: ApiDate.toApi(timestamp),
  };
}
