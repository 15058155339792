/**
 * Note: a release seed (_bootstrap_create-feature-settings) automatically
 * adds new Feature Setting Types to the DB
 */
export enum FeatureSettingType {
  MAPS = 'MAPS',
  AGE_BREAKDOWN = 'AGE_BREAKDOWN',
  GENDER_BREAKDOWN = 'GENDER_BREAKDOWN',
  DISTRICT_BREAKDOWN = 'DISTRICT_BREAKDOWN',
  COUNCIL_DISTRICT_BREAKDOWN = 'COUNCIL_DISTRICT_BREAKDOWN',
  PREMIUM_BREAKDOWNS = 'PREMIUM_BREAKDOWNS',
  GUEST_RESPONSES = 'GUEST_RESPONSES',
  PREMIUM_LIBRARY = 'PREMIUM_LIBRARY',
  POLCO_LIVE = 'POLCO_LIVE',
  MAILINGS = 'MAILINGS',
  ACCESS_SETTINGS = 'ACCESS_SETTINGS',
  CENSUS_BLOCK_GROUP_LOCATION = 'CENSUS_BLOCK_GROUP_LOCATION',
  CONDITIONAL_LOGIC = 'CONDITIONAL_LOGIC',
  ADMIN_SEATS = 'ADMIN_SEATS',
  PRINTABLE_PDF_VIEW = 'PRINTABLE_PDF_VIEW',
  TRACK_DOMAINS = 'TRACK_DOMAINS',
  TRACK_DASHBOARD = 'TRACK_DASHBOARD',
  TRACK_SAFETY = 'TRACK_SAFETY',
  TRACK_ECONOMY = 'TRACK_ECONOMY',
  TRACK_COMMUNITY_DESIGN = 'TRACK_COMMUNITY_DESIGN', //should be land use ?
  TRACK_MOBILITY = 'TRACK_MOBILITY',
  TRACK_OLDER_ADULTS = 'TRACK_OLDER_ADULTS',
  TRACK_UTILITIES = 'TRACK_UTILITIES',
  TRACK_NATURAL_ENVIRONMENT = 'TRACK_NATURAL_ENVIRONMENT',
  TRACK_PARKS_AND_RECREATION = 'TRACK_PARKS_AND_RECREATION',
  TRACK_HEALTH_AND_WELLNESS = 'TRACK_HEALTH_AND_WELLNESS',
  TRACK_EDUCATION_ARTS_AND_CULTURE = 'TRACK_EDUCATION_ARTS_AND_CULTURE',
  TRACK_INCLUSIVITY_AND_ENGAGEMENT = 'TRACK_INCLUSIVITY_AND_ENGAGEMENT',
  TRACK_FINANCE = 'TRACK_FINANCE',
  AUTOMATIC_WEIGHTING = 'AUTOMATIC_WEIGHTING',
  BALANCING_ACT_ADMIN_CONTENT_ENABLED = 'BALANCING_ACT_ADMIN_CONTENT_ENABLED',
  CREATE_IDEAS = 'CREATE_IDEAS',
  HIDE_TRACK_UPSELL = 'HIDE_TRACK_UPSELL',
  AI_ASSISTANT = 'AI_ASSISTANT',
  GRANT_WRITER_ASSISTANT = 'GRANT_WRITER_ASSISTANT',
  ENGAGE_CONTENT_LIMIT = 'ENGAGE_CONTENT_LIMIT',
  SAVED_DATA_POINTS = 'SAVED_DATA_POINTS',
}

// The order here currently determines the FE order.
// This is not really a great way to do order, but for now the FE does no
// sorting so the sort just ends up coming from this list. If we want to change the
// sorting, I would suggest doing so via some new FE infra, not here
export const BreakdownFeatures = [
  FeatureSettingType.GENDER_BREAKDOWN,
  FeatureSettingType.AGE_BREAKDOWN,
  FeatureSettingType.DISTRICT_BREAKDOWN,
  FeatureSettingType.COUNCIL_DISTRICT_BREAKDOWN,
  FeatureSettingType.CENSUS_BLOCK_GROUP_LOCATION,
  FeatureSettingType.PREMIUM_BREAKDOWNS,
];

export type TrackDomainTypes = Extract<
  FeatureSettingType,
  | FeatureSettingType.TRACK_INCLUSIVITY_AND_ENGAGEMENT
  | FeatureSettingType.TRACK_SAFETY
  | FeatureSettingType.TRACK_ECONOMY
  | FeatureSettingType.TRACK_COMMUNITY_DESIGN
  | FeatureSettingType.TRACK_HEALTH_AND_WELLNESS
  | FeatureSettingType.TRACK_EDUCATION_ARTS_AND_CULTURE
  | FeatureSettingType.TRACK_MOBILITY
  | FeatureSettingType.TRACK_UTILITIES
  | FeatureSettingType.TRACK_NATURAL_ENVIRONMENT
  | FeatureSettingType.TRACK_PARKS_AND_RECREATION
  | FeatureSettingType.TRACK_FINANCE
>;

export const TrackDomainsFeatureSettings: Record<
  TrackDomainTypes,
  FeatureSettingType
> = {
  [FeatureSettingType.TRACK_INCLUSIVITY_AND_ENGAGEMENT]:
    FeatureSettingType.TRACK_INCLUSIVITY_AND_ENGAGEMENT,
  [FeatureSettingType.TRACK_SAFETY]: FeatureSettingType.TRACK_SAFETY,
  [FeatureSettingType.TRACK_ECONOMY]: FeatureSettingType.TRACK_ECONOMY,
  [FeatureSettingType.TRACK_COMMUNITY_DESIGN]:
    FeatureSettingType.TRACK_COMMUNITY_DESIGN,
  [FeatureSettingType.TRACK_HEALTH_AND_WELLNESS]:
    FeatureSettingType.TRACK_HEALTH_AND_WELLNESS,
  [FeatureSettingType.TRACK_EDUCATION_ARTS_AND_CULTURE]:
    FeatureSettingType.TRACK_EDUCATION_ARTS_AND_CULTURE,
  [FeatureSettingType.TRACK_MOBILITY]: FeatureSettingType.TRACK_MOBILITY,
  [FeatureSettingType.TRACK_UTILITIES]: FeatureSettingType.TRACK_UTILITIES,
  [FeatureSettingType.TRACK_NATURAL_ENVIRONMENT]:
    FeatureSettingType.TRACK_NATURAL_ENVIRONMENT,
  [FeatureSettingType.TRACK_PARKS_AND_RECREATION]:
    FeatureSettingType.TRACK_PARKS_AND_RECREATION,
  [FeatureSettingType.TRACK_FINANCE]: FeatureSettingType.TRACK_FINANCE,
};
export type NumericFeatureSetting =
  | FeatureSettingType.ADMIN_SEATS
  | FeatureSettingType.ENGAGE_CONTENT_LIMIT
  | FeatureSettingType.AI_ASSISTANT
  | FeatureSettingType.GRANT_WRITER_ASSISTANT
  | FeatureSettingType.SAVED_DATA_POINTS
  | FeatureSettingType.TRACK_DOMAINS;
