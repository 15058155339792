import {
  OperationVariables,
  QueryResult,
  useQuery,
  QueryHookOptions,
  ApolloClient,
} from '@apollo/client';
import { QueryComponentOptions as ApolloQueryProps } from '@apollo/client/react/components';
import { ApolloCache, NormalizedCacheObject } from '@apollo/client/cache';
import { DocumentNode } from 'graphql';

// NOTE: if we determine some options are often the same when a query is used, we could specify
// defaults here

export interface TypedQueryOptions<TVariables> {
  readonly query: DocumentNode;
  readonly variables: TVariables;
  readonly context?: any;
}

export interface QueryInfoContext extends Record<string, any> {
  readonly neverBatch?: boolean;
}
export interface QueryInfo<TData, TVariables> {
  readonly document: DocumentNode;
  readonly context?: QueryInfoContext;
  refetchInfo(vars: TVariables): TypedQueryOptions<TVariables>;
  readonly _data?: TData; // never used
  readonly _variables?: TVariables; // never used
}

export type ToQueryResult<T extends QueryInfo<any, any>> = QueryResult<
  NonNullable<T['_data']>,
  NonNullable<T['_variables']>
>;

export type ToQueryProps<T extends QueryInfo<any, any>> = QueryProps<
  NonNullable<T['_data']>,
  NonNullable<T['_variables']>
>;

export function queryInfo<TData, TVariables = OperationVariables>(
  document: DocumentNode,
  context?: QueryInfoContext
): QueryInfo<TData, TVariables> {
  return {
    document,
    refetchInfo(variables) {
      return {
        query: document,
        variables,
      };
    },
    context,
  };
}

export type QueryProps<TData, TVariables> = Omit<
  ApolloQueryProps<TData, TVariables>,
  'query' | 'skip'
> & { readonly query: QueryInfo<TData, TVariables> };

export function readQueryFor<TData, TVariables>(
  client: ApolloClient<NormalizedCacheObject> | ApolloCache<any>,
  query: QueryInfo<TData, TVariables>
) {
  return function (variables: TVariables) {
    return client.readQuery<TData, TVariables>({
      query: query.document,
      variables,
    });
  };
}

export function writeQueryFor<TData, TVariables>(
  client: ApolloClient<NormalizedCacheObject> | ApolloCache<any>,
  query: QueryInfo<TData, TVariables>
) {
  return function (variables: TVariables, data: TData) {
    return client.writeQuery<TData, TVariables>({
      query: query.document,
      variables,
      data,
    });
  };
}

/**
 * Keep apollo client 2.0 behavior by default -
 * when vars change, keep last `data` value during refecth
 */
export function useQueryInfo<TData, TVariables>(
  qi: QueryInfo<TData, TVariables>,
  options: Omit<QueryHookOptions<TData, TVariables>, 'onCompleted'> & {
    // TODO look into why, despite the fact that Apollo's type signature
    //      says it is not null, sometimes it is.
    readonly onCompleted?: (data: TData | null) => void;
    readonly dontUsePreviousDataAsData?: boolean;
  }
): QueryResult<TData, TVariables> & {
  readonly usedPreviousData: boolean;
} {
  const { dontUsePreviousDataAsData, context, ...opts } = options;
  const ret = useQuery(qi.document, {
    ...opts,
    context: { ...qi?.context, ...context }, // passed in options overwrite query level options
  });
  if (!dontUsePreviousDataAsData && !ret.data && ret.previousData) {
    return {
      ...ret,
      data: ret.previousData,
      usedPreviousData: true,
    };
  }
  return {
    ...ret,
    usedPreviousData: false,
  };
}
