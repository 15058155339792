import { BenchmarkFilter, TrackAreaMode } from 'core';
import React from 'react';
import {
  ContentUnavailableType,
  ContentUnavailableWell,
} from '../content-unavailable';
import { DashboardCard } from '../dashboard-card';
import { hiddenOptionState, TrackCardOptionType } from '../track-card-options-menu';

interface Props {
  readonly type: ContentUnavailableType;
  readonly label?: string;
}

const dataPointLabel = 'data point';

export const VisualizationUnavailable: React.FC<Props> = (props) => {
  return props.label ? (
    <DashboardCard
      areaMode={TrackAreaMode.LOCAL}
      benchmarkFilter={BenchmarkFilter.DEFAULT}
      saveVisualization={{
        type: TrackCardOptionType.SAVE_VISUALIZATION,
        state: hiddenOptionState(),
      }}
      title={props.label}
    >
      <ContentUnavailableWell contentTypeLabel={dataPointLabel} type={props.type} />
    </DashboardCard>
  ) : (
    <ContentUnavailableWell contentTypeLabel={dataPointLabel} type={props.type} />
  );
};
