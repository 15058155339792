import { wrap } from 'core';
import * as React from 'react';
import { EventPropagationStopper, MaterialIcon, MaterialIconName } from '../base';
import { Tooltip } from '../tooltip';
import { Dropdown as BsDropdown } from 'react-bootstrap';
import './styles.scss';
import { IconButton } from '@material-ui/core';
import { useId } from 'react-use-id-hook';

const baseClass = 'pn-track-card-options-menu';

export enum TrackCardOptionType {
  SAVE_VISUALIZATION = 'SAVE_VISUALIZATION',
  SAVE_IMAGE = 'SAVE_IMAGE',
  OPEN_GOAL_MODAL = 'OPEN_GOAL_MODAL',
}

type OptionState =
  | { readonly status: 'hidden' }
  | { readonly status: 'disabled'; readonly reason: string }
  | { readonly status: 'enabled'; readonly onClick: () => void };

interface OptionPropsBase {
  readonly state: OptionState;
}

export interface SaveVisualizationOptionProps extends OptionPropsBase {
  readonly type: TrackCardOptionType.SAVE_VISUALIZATION;
}

export interface SaveImageOptionProps extends OptionPropsBase {
  readonly type: TrackCardOptionType.SAVE_IMAGE;
}

export interface OpenGoalModalOptionProps extends OptionPropsBase {
  readonly type: TrackCardOptionType.OPEN_GOAL_MODAL;
}

export interface SaveableDataPoint {
  readonly saveVisualization: SaveVisualizationOptionProps;
}

interface TrackCardOptionsMenuProps {
  readonly saveVisualization: SaveVisualizationOptionProps;
  readonly setGoalModalOpen: OpenGoalModalOptionProps;
  readonly generateImage: SaveImageOptionProps;
  readonly className?: string;
}

export const TrackCardOptionsMenu: React.FC<TrackCardOptionsMenuProps> = (props) => {
  const { generateImage, saveVisualization, setGoalModalOpen } = props;

  const tooltipId = useId();

  const generateImageHidden = optionHidden(generateImage);
  const saveVisualizationHidden = optionHidden(saveVisualization);
  const setGoalModalOpenHidden = optionHidden(setGoalModalOpen);

  // Do not render anything when no actions available
  if (generateImageHidden && saveVisualizationHidden && setGoalModalOpenHidden) {
    return null;
  }

  // Render download icon when only image generation is available
  if (
    generateImage.state.status === 'enabled' &&
    saveVisualizationHidden &&
    setGoalModalOpenHidden
  ) {
    return (
      <Tooltip
        className="font-size-sm font-weight-normal no-show-in-image"
        content="Download as Image"
        id={tooltipId}
      >
        <IconButton
          className={`no-show-in-image p-1 save-image u-no-print`}
          onClick={generateImage.state.onClick}
        >
          <MaterialIcon
            ariaLabel="Download as Image"
            icon={MaterialIconName.DOWNLOAD}
            iconType="material-icons-outlined"
          />
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <BsDropdown className={`no-show-in-image ${props.className || ''} ${baseClass}`}>
      <EventPropagationStopper>
        <div className={`${baseClass}-toggle-wrapper`}>
          <BsDropdown.Toggle
            className={`${baseClass}-action-toggle p-0 m-0`}
            variant="link"
          >
            <MaterialIcon
              className={`${baseClass}-action-toggle-icon`}
              icon={MaterialIconName.MORE_VERT}
            />
          </BsDropdown.Toggle>
        </div>
      </EventPropagationStopper>

      <BsDropdown.Menu alignRight>
        <EventPropagationStopper>
          {[generateImage, saveVisualization, setGoalModalOpen].map((option) => {
            return wrap(() => {
              if (option.state.status === 'enabled') {
                return (
                  <BsDropdown.Item
                    className={`${baseClass}-dropDown-item track-option-${option.type}`}
                    key={option.type}
                    onClick={option.state.onClick}
                  >
                    <div>{DROPDOWN_COPY[option.type]}</div>
                  </BsDropdown.Item>
                );
              }
              if (option.state.status === 'disabled') {
                return (
                  <Tooltip
                    className={`${baseClass}-tooltip font-weight-normal`}
                    content=""
                    htmlContent={<p>{option.state.reason}</p>}
                    id={`${option.type}-tooltip`}
                    place="bottom"
                  >
                    <BsDropdown.Item
                      className={`${baseClass}-dropDown-item save-data-point track-option-${option.type}`}
                      disabled
                    >
                      <div>{DROPDOWN_COPY[option.type]}</div>
                    </BsDropdown.Item>
                  </Tooltip>
                );
              }
              return null;
            });
          })}
        </EventPropagationStopper>
      </BsDropdown.Menu>
    </BsDropdown>
  );
};
TrackCardOptionsMenu.displayName = 'TrackCardOptionsMenu';

const DROPDOWN_COPY = {
  [TrackCardOptionType.SAVE_IMAGE]: 'Save as Image',
  [TrackCardOptionType.SAVE_VISUALIZATION]: 'Save Data Point',
  [TrackCardOptionType.OPEN_GOAL_MODAL]: 'Add/Edit Goal',
};

function optionHidden<T extends OptionPropsBase>(option: T): boolean {
  return option.state.status === 'hidden';
}

export function enabledOptionState(onClick: () => void): OptionState {
  return {
    status: 'enabled',
    onClick,
  };
}

export function disabledOptionState(reason: string): OptionState {
  return {
    status: 'disabled',
    reason,
  };
}

export function hiddenOptionState(): OptionState {
  return {
    status: 'hidden',
  };
}
