import {
  TrackVariable as GqlTrackVariable,
  TrackVariableWithDistribution as GqlTrackVariableWithDistribution,
} from 'client/shared/graphql-client/graphql-operations.g';

import * as Gql from 'client/shared/graphql-client/graphql-operations.g';
import {
  AnalyticsDomain,
  ApiDate,
  FipsAreaWithShortName,
  TrackDatum,
  TrackDatumWithDistributions,
  TrackDomain,
  TrackDomainsData,
  TrackVariable,
  TrackVariableWithDistributions,
} from 'core';
import { PerformanceDataTx } from './publishing-entity-performance-data';
import uuid from 'uuid';

type gqlTrackDatum = NonNullable<
  GqlTrackVariable['areasData'][0]['performanceData'][0]
>;

type GqlOverviewTrackDomains = NonNullable<
  Gql.AdminPublishingEntityPerformanceData['openPublishingEntityById']
>;

type GqlOverviewComparisonGroupTrackDomains = NonNullable<
  Gql.AdminComparisonGroupTrackOverview['openComparisonGroupById']
>;

type gqlTrackDatumWithDistributions = NonNullable<
  GqlTrackVariableWithDistribution['areasData'][0]['performanceData'][0]
>;

type gqlFipsArea = NonNullable<GqlTrackVariable['areasData'][0]['area']>;

export namespace TrackVariableTx {
  export function gqlTrackDomainsDataToClient(
    trackDomainsData: GqlOverviewTrackDomains
  ): TrackDomainsData {
    return {
      [AnalyticsDomain.SAFETY]: gqlTrackDomainToClient(trackDomainsData.SAFETY),
      [AnalyticsDomain.ECONOMY]: gqlTrackDomainToClient(trackDomainsData.ECONOMY),
      [AnalyticsDomain.COMMUNITY_DESIGN]: gqlTrackDomainToClient(
        trackDomainsData.COMMUNITY_DESIGN
      ),
      [AnalyticsDomain.HEALTH_AND_WELLNESS]: gqlTrackDomainToClient(
        trackDomainsData.HEALTH_AND_WELLNESS
      ),
      [AnalyticsDomain.INCLUSIVITY_AND_ENGAGEMENT]: gqlTrackDomainToClient(
        trackDomainsData.INCLUSIVITY_AND_ENGAGEMENT
      ),
      [AnalyticsDomain.EDUCATION_ARTS_CULTURE]: gqlTrackDomainToClient(
        trackDomainsData.EDUCATION_ARTS_CULTURE
      ),
      [AnalyticsDomain.GOVERNANCE]: gqlTrackDomainToClient(
        trackDomainsData.GOVERNANCE
      ),
      [AnalyticsDomain.COMMUNITY_LIVABILITY]: gqlTrackDomainToClient(
        trackDomainsData.COMMUNITY_LIVABILITY
      ),
      [AnalyticsDomain.COMMUNITY_CHARACTERISTICS]: gqlTrackDomainToClient(
        trackDomainsData.COMMUNITY_CHARACTERISTICS
      ),
      [AnalyticsDomain.MOBILITY]: gqlTrackDomainToClient(trackDomainsData.MOBILITY),
      [AnalyticsDomain.NATURAL_ENVIRONMENT]: gqlTrackDomainToClient(
        trackDomainsData.NATURAL_ENVIRONMENT
      ),
      [AnalyticsDomain.PARKS_AND_RECREATION]: gqlTrackDomainToClient(
        trackDomainsData.PARKS_AND_RECREATION
      ),
      [AnalyticsDomain.UTILITIES]: gqlTrackDomainToClient(
        trackDomainsData.UTILITIES
      ),
      [AnalyticsDomain.FINANCE]: gqlTrackDomainToClient(trackDomainsData.FINANCE),
    };
  }

  export function gqlTrackComparisonGroupDomainsDataToClient(
    trackDomainsData: GqlOverviewComparisonGroupTrackDomains
  ): TrackDomainsData {
    return {
      [AnalyticsDomain.SAFETY]: gqlTrackComparisonGroupDomainToClient(
        trackDomainsData.SAFETY
      ),
      [AnalyticsDomain.ECONOMY]: gqlTrackComparisonGroupDomainToClient(
        trackDomainsData.ECONOMY
      ),
      [AnalyticsDomain.COMMUNITY_DESIGN]: gqlTrackComparisonGroupDomainToClient(
        trackDomainsData.COMMUNITY_DESIGN
      ),
      [AnalyticsDomain.HEALTH_AND_WELLNESS]: gqlTrackComparisonGroupDomainToClient(
        trackDomainsData.HEALTH_AND_WELLNESS
      ),
      [AnalyticsDomain.INCLUSIVITY_AND_ENGAGEMENT]:
        gqlTrackComparisonGroupDomainToClient(
          trackDomainsData.INCLUSIVITY_AND_ENGAGEMENT
        ),
      [AnalyticsDomain.EDUCATION_ARTS_CULTURE]:
        gqlTrackComparisonGroupDomainToClient(
          trackDomainsData.EDUCATION_ARTS_CULTURE
        ),
      [AnalyticsDomain.GOVERNANCE]: {
        id: uuid.v4(),
        indexScores: [],
        indicators: [],
        sentimentValues: [],
      },
      [AnalyticsDomain.COMMUNITY_LIVABILITY]: {
        id: uuid.v4(),
        indexScores: [],
        indicators: [],
        sentimentValues: [],
      },
      [AnalyticsDomain.FINANCE]: {
        id: uuid.v4(),
        indexScores: [],
        indicators: [],
        sentimentValues: [],
      },
      [AnalyticsDomain.COMMUNITY_CHARACTERISTICS]:
        gqlTrackComparisonGroupDomainToClient(
          trackDomainsData.COMMUNITY_CHARACTERISTICS
        ),
      [AnalyticsDomain.MOBILITY]: gqlTrackComparisonGroupDomainToClient(
        trackDomainsData.MOBILITY
      ),
      [AnalyticsDomain.NATURAL_ENVIRONMENT]: gqlTrackComparisonGroupDomainToClient(
        trackDomainsData.NATURAL_ENVIRONMENT
      ),
      [AnalyticsDomain.PARKS_AND_RECREATION]: gqlTrackComparisonGroupDomainToClient(
        trackDomainsData.PARKS_AND_RECREATION
      ),
      [AnalyticsDomain.UTILITIES]: gqlTrackComparisonGroupDomainToClient(
        trackDomainsData.UTILITIES
      ),
    };
  }

  export function gqlTrackDomainToClient(
    trackDomainData: Gql.DomainTrackVariables
  ): TrackDomain {
    return {
      id: trackDomainData.id,
      indexScores: trackDomainData.indexScores.map(
        gqlTrackVariableWithDistributionToClient
      ),
      indicators: trackDomainData.indicators.map(gqlTrackVariableToClient),
      sentimentValues: trackDomainData.sentimentValues.map(gqlTrackVariableToClient),
    };
  }

  export function gqlTrackComparisonGroupDomainToClient(
    trackDomainData: Pick<
      Gql.ComparisonGroupTrackDomain,
      'id' | 'domain' | 'fips' | '__typename'
    > &
      Partial<Gql.ComparisonGroupTrackDomain>
  ): TrackDomain {
    return {
      id: trackDomainData.id,
      indexScores: trackDomainData.indexScores
        ? trackDomainData.indexScores.map(gqlTrackVariableWithDistributionsToClient)
        : [],
      indicators: trackDomainData.indicators
        ? trackDomainData.indicators.map(gqlTrackVariableToClient)
        : [],
      sentimentValues: [],
    };
  }

  export function gqlTrackVariableToClient(
    trackVariable: GqlTrackVariable | Gql.TrackVariableWithDistributions
  ): TrackVariable {
    return {
      id: trackVariable.id,
      name: trackVariable.name,
      domains: trackVariable.domains.map((d) =>
        PerformanceDataTx.gqlVariableDomaintoClient(d)
      ),
      statisticType: trackVariable.statisticType,
      valueType: trackVariable.valueType,
      suffix: trackVariable.suffix,
      label: trackVariable.label,
      description: trackVariable.description,
      source: trackVariable.source,
      dateLevel: trackVariable.dateLevel,
      includedInIndex: trackVariable.includedInIndex,
      direction: PerformanceDataTx.gqlVariableDirectionToClient(
        trackVariable.direction
      ),
      isDefault: trackVariable.isDefault,
      isOA: trackVariable.isOA,
      demographicSegment: trackVariable.demographicSegment,
      areasData: trackVariable.areasData.map((ad) => ({
        fipsArea: gqlFipsAreaToClient(ad.area),
        performanceData: ad.performanceData.map(gqlTrackDatumToClient),
        arPredictiveData: [],
        gpalPredictiveData: [],
      })),
    };
  }

  export function gqlTrackVariableWithDistributionToClient(
    trackVariable: GqlTrackVariableWithDistribution
  ): TrackVariableWithDistributions {
    return {
      ...gqlTrackVariableToClient(trackVariable),
      areasData: trackVariable.areasData.map((ad) => ({
        fipsArea: gqlFipsAreaToClient(ad.area),
        performanceData: ad.performanceData.map(
          gqlTrackDatumWithDistributionsToClient
        ),
        arPredictiveData: [],
        gpalPredictiveData: [],
      })),
    };
  }
  export function gqlTrackVariableWithDistributionsToClient(
    trackVariable: Gql.TrackVariableWithDistributions | Gql.TrackVariable
  ): TrackVariableWithDistributions {
    return {
      ...gqlTrackVariableToClient(trackVariable),
      areasData: trackVariable.areasData.map((ad) => ({
        fipsArea: gqlFipsAreaToClient(ad.area),
        performanceData: ad.performanceData.map((pd) =>
          gqlTrackDatumWithDistributionsToClient({
            ...pd,
            distributions: 'distributions' in pd ? pd.distributions : [],
          })
        ),
        arPredictiveData: [],
        gpalPredictiveData: [],
      })),
    };
  }

  export function gqlTrackDatumToClient(trackDatum: gqlTrackDatum): TrackDatum {
    return {
      id: trackDatum.id,
      value: trackDatum.value,
      fipsArea: gqlFipsAreaToClient(trackDatum.fipsArea),
      recordedAt: ApiDate.fromApi(trackDatum.recordedAt),
      benchmarkValues: trackDatum.benchmarkValues.map((bv) => ({
        filter: bv.filter,
        value: bv.value,
        percentileBucketIndex: bv.percentileBucketIndex,
      })),
      previousValue: trackDatum.previousValue
        ? {
            id: trackDatum.previousValue.id,
            value: trackDatum.previousValue.value,
            recordedAt: ApiDate.fromApi(trackDatum.previousValue.recordedAt),
          }
        : undefined,
    };
  }

  export function gqlTrackDatumWithDistributionsToClient(
    trackDatum: gqlTrackDatumWithDistributions
  ): TrackDatumWithDistributions {
    return {
      ...gqlTrackDatumToClient(trackDatum),
      distributions: PerformanceDataTx.gqlPerformanceDatumDistribtuionToClient(
        trackDatum.distributions
      ),
    };
  }

  function gqlFipsAreaToClient(fipsArea: gqlFipsArea): FipsAreaWithShortName {
    return {
      id: fipsArea.id,
      name: fipsArea.name,
      type: fipsArea.type,
      shortName: fipsArea.shortName,
    };
  }
}
