import * as React from 'react';
import ReactWordCloud from 'react-d3-cloud';

import { loadingBarHexColorsAccessible } from 'client/shared/core/colors';
import { maybeMap, toMutable } from 'core';
import _ from 'lodash';
import { Word } from 'react-d3-cloud/lib/WordCloud';

export interface Props {
  readonly height: number;
  readonly width: number;
  readonly maxWords?: number;
  readonly words: readonly Word[];
  readonly colors?: readonly string[];

  // IMPORTANT: given font sizes affects the rendering time of the word
  // cloud because of the algorithm used by d3-cloud. If drawing times become higher,
  // either sizes or height/width must be tuned for faster results
  readonly fontSizes?: readonly [number, number];
}

const FONT_SIZES: readonly [number, number] = [14, 40];
const FONT_FAMILY = '"Open Sans", sans-serif';
const DEFAULT_PALETTE = loadingBarHexColorsAccessible;

export const WordCloud: React.FC<Props> = (p) => {
  const data = React.useMemo(
    () =>
      _.takeRight(
        _.sortBy(p.words, (w) => w.value),
        p.maxWords
      ) as Word[],
    [p.words, p.maxWords]
  );
  const colors = React.useMemo(
    () => maybeMap(p.colors, toMutable) ?? DEFAULT_PALETTE,
    [p.colors]
  );
  const minFontSize = p.fontSizes?.[0] ?? FONT_SIZES[0];
  const maxFontSize = p.fontSizes?.[1] ?? FONT_SIZES[1];
  const fontSize = React.useCallback(
    (_word, i) => minFontSize + maxFontSize * (i / (p.maxWords ?? 20)),
    [p.maxWords, minFontSize, maxFontSize]
  );
  const fill = React.useCallback((_word, i) => colors[i % colors.length], [colors]);
  const random = React.useCallback(() => 0.1337, []);

  if (process.env.NODE_ENV === 'test') {
    return <div>{p.words.map((w) => w.text).join(' / ')}</div>;
  }
  return (
    <div style={{ height: p.height, width: p.width, alignSelf: 'center' }}>
      <ReactWordCloud
        data={data}
        fill={fill}
        font={FONT_FAMILY}
        fontSize={fontSize}
        height={p.height}
        random={random}
        rotate={0}
        width={p.width}
      />
    </div>
  );
};

WordCloud.defaultProps = {
  colors: DEFAULT_PALETTE,
  fontSizes: FONT_SIZES,
  maxWords: 20,
};
