import { DomainChartContainer } from 'client/shared/containers/domain-chart-container';
import { getFilteredBenchmarkFooter } from 'client/shared/core/performance-data';
import { domainDescriptions, domainLabels } from 'client/shared/core/track/labels';
import {
  ActivationState,
  BenchmarkFilter,
  MapExtentBounds,
  NationalDataFips,
  SafeRecordDictionary,
  StatisticType,
  TrackAreaMode,
  TrackDatumWithDistributions,
  TrackVariableWithDistributions,
  VariableDisplayType,
  VisualizationType,
  getMostRecentDatumCurrentPub,
  wrap,
} from 'core';
import * as React from 'react';
import { ContentUnavailableType } from '../content-unavailable';
import { DashboardCard } from '../dashboard-card';
import { DomainCardContent } from '../domain-card';
import { CommunityStatisticsCard } from '../domain-community-statistics-card';
import { VisualizationUnavailable } from './not-found';
import { GroupIndexScoresContainer } from 'client/shared/containers/group-index-scores-container';
import { GroupIndicatorCard } from '../group-indicator-card';
import { MultiPolygon } from '@turf/helpers';
import { GqlGoal } from 'client/admin/hooks/use-track-goals';
import {
  disabledOptionState,
  enabledOptionState,
  hiddenOptionState,
  SaveVisualizationOptionProps,
  TrackCardOptionType,
} from '../track-card-options-menu';

export enum EmbedDataPointContext {
  CONTENT_POST = 'CONTENT_POST',
  VISUALIZATION = 'VISUALIZATION',
  POLLY = 'POLLY',
  TRACK = 'TRACK',
}
export interface VisualizationData {
  readonly recordedAt: Date | null;
  readonly state: ActivationState;
  readonly benchmarkFilter: BenchmarkFilter;
  readonly visualizationType: VisualizationType | null;
  readonly trackVariable: TrackVariableWithDistributions;
  readonly baseFips: string | null;
  readonly id?: string;
  readonly comparisonGroupId?: string;
  readonly mapData: {
    readonly fipsAreasShapes: SafeRecordDictionary<string, MultiPolygon>;
    readonly mapBoundCoordinates: MapExtentBounds | null;
  };
  readonly publishingEntityId: string;
}
export interface VisualizationProps {
  readonly benchmarkFilter: BenchmarkFilter;
  readonly visualizationType: VisualizationType | null;
  readonly trackVariable?: TrackVariableWithDistributions;
  readonly baseFips: string | null;
  readonly comparisonGroupId?: string;
  readonly mapData: {
    readonly fipsAreasShapes: SafeRecordDictionary<string, MultiPolygon>;
    readonly mapBoundCoordinates: MapExtentBounds | null;
  };
  readonly publishingEntityId: string;
  readonly expanded: boolean;
  readonly postEmbed?: boolean;
  readonly toggleExpanded?: () => void;
  readonly topLevelEmbed?: boolean;
  readonly label?: string;
  readonly goal?: GqlGoal;
  readonly strategicPlanId?: string;
  readonly onSaveVisualization?: (chart: VisualizationType | null) => void;
  readonly hasManageSavedDataPointsPerm?: boolean;
  readonly disableSaveDataPointsReason?: string;
  readonly embedContext: EmbedDataPointContext;
}

export const Visualization: React.FC<
  VisualizationProps & {
    readonly trackVariable: TrackVariableWithDistributions;
  }
> = (props) => {
  const [chartType, setChartType] = React.useState<VisualizationType | null>(
    props.visualizationType
  );
  const { benchmarkFilter, visualizationType, trackVariable: variable } = props;

  const { embedContext } = props;

  const { areasData } = variable;

  // need to grab either the basefips from the array if there are multiple, or if theres only one we can assume its the basefips
  const currentFips = variable.areasData.find(
    (d) => d.fipsArea.id === props.baseFips
  )?.fipsArea;
  const groupDoesNotContainPublisher = !currentFips;

  const areaMode =
    currentFips?.id === NationalDataFips
      ? TrackAreaMode.NATIONAL
      : TrackAreaMode.LOCAL;

  const footer =
    areaMode === TrackAreaMode.LOCAL
      ? getFilteredBenchmarkFooter(benchmarkFilter)
      : null;

  const latestPerformanceData = currentFips
    ? getMostRecentDatumCurrentPub<
        TrackDatumWithDistributions,
        TrackVariableWithDistributions
      >(currentFips, variable)
    : undefined;

  const saveVisualization: SaveVisualizationOptionProps = React.useMemo(
    () => ({
      type: TrackCardOptionType.SAVE_VISUALIZATION,
      state: wrap(() => {
        if (!props.hasManageSavedDataPointsPerm || !props.onSaveVisualization) {
          return hiddenOptionState();
        }
        if (props.disableSaveDataPointsReason) {
          return disabledOptionState(props.disableSaveDataPointsReason);
        }
        return enabledOptionState(() => props.onSaveVisualization?.(chartType));
      }),
    }),
    [chartType, props]
  );

  if (areasData.length > 1 && props.comparisonGroupId) {
    return props.trackVariable.statisticType === StatisticType.INDEX ? (
      <DashboardCard
        areaMode={areaMode}
        benchmarkFilter={benchmarkFilter}
        chartTypeToSave={chartType ?? undefined}
        currentFips={currentFips?.id}
        description={domainDescriptions[variable.domains[0].domain]}
        imageGenerationInfo={{
          name: `${domainLabels[variable.domains[0].domain]} Index Score Card`,
          buttonClass: 'visualization-picker-index-score',
        }}
        recentValue={latestPerformanceData}
        saveVisualization={saveVisualization}
        title={domainLabels[variable.domains[0].domain]}
        variable={variable}
      >
        <GroupIndexScoresContainer
          benchmarkFilter={benchmarkFilter}
          chartType={chartType}
          comparisonGroupId={props.comparisonGroupId}
          currentFips={currentFips}
          displayType={VariableDisplayType.DEFAULT}
          domain={variable.domains[0].domain}
          embedContext={embedContext}
          fipsShapeByFipsCode={props.mapData.fipsAreasShapes}
          groupDoesNotContainPublisher={groupDoesNotContainPublisher}
          hideAiQuickAction
          mapBoundCoordinates={props.mapData.mapBoundCoordinates}
          setChartType={
            ((type: VisualizationType | null) => {
              if (!type || type === chartType) {
                setChartType(VisualizationType.HISTOGRAM);
              } else {
                setChartType(type);
              }
            }) as typeof setChartType
          }
          trackVariables={[variable]}
        />
      </DashboardCard>
    ) : (
      <GroupIndicatorCard
        benchmarkFilter={benchmarkFilter}
        comparisonGroupId={props.comparisonGroupId}
        currentFips={currentFips?.id}
        displayType={VariableDisplayType.DEFAULT}
        domain={variable.domains[0].domain}
        embedContext={embedContext}
        expanded={props.expanded}
        fipsShapeByFipsCode={props.mapData.fipsAreasShapes}
        goal={props.goal}
        hideAiQuickAction
        indicator={variable}
        mapBoundingCoordinates={props.mapData.mapBoundCoordinates}
        planId={props.strategicPlanId}
        publishingEntityId={props.publishingEntityId}
        saveVisualization={saveVisualization}
        showFooter={!!variable.direction}
        toggleExpanded={props.toggleExpanded}
        toggleVisualization={(_varId, type) => {
          if (type === chartType) {
            setChartType(null);
          } else {
            setChartType(type);
          }
        }}
        variableVisualizationType={chartType}
      />
    );
  }

  if (!currentFips) {
    return (
      <VisualizationUnavailable
        label={props.label}
        type={ContentUnavailableType.NOT_FOUND}
      />
    );
  }

  switch (visualizationType) {
    case VisualizationType.LINE: {
      return variable.statisticType === StatisticType.INDICATOR ||
        variable.statisticType === StatisticType.SENTIMENT_VALUE ? (
        <CommunityStatisticsCard
          benchmarkFilter={benchmarkFilter}
          currentFips={currentFips}
          embedContext={embedContext}
          expanded={props.expanded}
          goal={props.goal}
          hideAiQuickAction
          planId={props.strategicPlanId}
          publishingEntityId={props.publishingEntityId}
          saveVisualization={saveVisualization}
          showFooter={areaMode === TrackAreaMode.LOCAL && !!variable.direction}
          toggleExpanded={props.toggleExpanded}
          variable={variable}
        />
      ) : (
        <DashboardCard
          areaMode={areaMode}
          benchmarkFilter={benchmarkFilter}
          chartTypeToSave={chartType ?? undefined}
          currentFips={currentFips?.id}
          description={domainDescriptions[variable.domains[0].domain]}
          embedContext={embedContext}
          imageGenerationInfo={{
            name: `${domainLabels[variable.domains[0].domain]} Index Score Card`,
            buttonClass: 'visualization-picker-index-score',
          }}
          saveVisualization={saveVisualization}
          title={domainLabels[variable.domains[0].domain]}
        >
          <DomainCardContent
            domain={variable.domains[0].domain}
            hideAiQuickAction
            indexScoreData={latestPerformanceData}
            variable={variable}
          />
          {footer && (
            <div className="font-size-sm py-2 mb-3 no-show-in-image">{footer}</div>
          )}
          <DomainChartContainer
            areaMode={areaMode}
            benchmarkFilter={benchmarkFilter}
            currentFips={currentFips}
            defaultChart={chartType ?? VisualizationType.HISTOGRAM}
            indexScore={variable}
            setChart={setChartType}
          />
        </DashboardCard>
      );
    }
    case VisualizationType.HISTOGRAM: {
      return (
        <DashboardCard
          areaMode={areaMode}
          benchmarkFilter={benchmarkFilter}
          chartTypeToSave={chartType ?? undefined}
          currentFips={currentFips?.id}
          description={domainDescriptions[variable.domains[0].domain]}
          embedContext={embedContext}
          imageGenerationInfo={{
            name: `${domainLabels[variable.domains[0].domain]} Index Score Card`,
            buttonClass: 'visualization-picker-index-score',
          }}
          saveVisualization={saveVisualization}
          title={domainLabels[variable.domains[0].domain]}
          variable={variable}
        >
          <DomainCardContent
            domain={variable.domains[0].domain}
            hideAiQuickAction
            indexScoreData={latestPerformanceData}
            variable={variable}
          />
          {footer && (
            <div className="font-size-sm py-2 mb-3 no-show-in-image">{footer}</div>
          )}
          <DomainChartContainer
            areaMode={
              currentFips.id === NationalDataFips
                ? TrackAreaMode.NATIONAL
                : TrackAreaMode.LOCAL
            }
            benchmarkFilter={benchmarkFilter}
            currentFips={currentFips}
            defaultChart={chartType ?? VisualizationType.HISTOGRAM}
            indexScore={variable}
            setChart={setChartType}
          />
        </DashboardCard>
      );
    }
    case VisualizationType.PROGRESS:
    default:
      return (
        <CommunityStatisticsCard
          benchmarkFilter={benchmarkFilter}
          currentFips={currentFips}
          embedContext={embedContext}
          expanded={props.expanded}
          goal={props.goal}
          hideAiQuickAction
          planId={props.strategicPlanId}
          publishingEntityId={props.publishingEntityId}
          saveVisualization={saveVisualization}
          showFooter={areaMode === TrackAreaMode.LOCAL && !!variable.direction}
          toggleExpanded={props.toggleExpanded}
          variable={variable}
        />
      );
  }
};
Visualization.displayName = 'Visualization';
