import { EMAIL_VALIDATION_REGEX, ShareableContentDomains } from 'core';
import {
  ClientQuestionSetId,
  ShareableContentType,
} from 'client/shared/core/question-set';
import { domainLabels } from 'client/shared/core/track/labels';
export {
  type AiAssistantQueryStatus,
  AiAssistantQueryStatusType,
  AiAssistantMessageRole,
  type AiAssistantMessage,
  type AiAssistantConversation,
  type AiAssistantMessageGroup,
  AiAssistantMessageType,
} from './ai-assistant-session';

export { PublishingEntityShareDestination } from './share';
export {
  type QuestionSet,
  type AccessSetting,
  AccessSettingType,
  type PollSet,
  type LiveEvent,
  type Survey,
} from './question';

export enum SidebarButtonType {
  HOMEPAGE = 'HOMEPAGE',
  CONFIGURATION = 'CONFIGURATION',
  CONTENT = 'CONTENT',
  PARTICIPANTS = 'PARTICIPANTS',
  POLCO_LIBRARY = 'POLCO_LIBRARY',
  PROFILE = 'PROFILE',
  IDEA_SUBMISSIONS = 'IDEA_SUBMISSIONS',
  OUTREACH = 'OUTREACH',
  SUPERADMIN = 'SUPERADMIN',
  BENCHMARK_REQUESTS = 'BENCHMARK_REQUESTS',
  SETTINGS = 'SETTINGS',
  POLCO_PLANS = 'POLCO_PLANS',
  TRACK_OVERVIEW = 'TRACK_OVERVIEW',
  TRACK_DOMAINS = 'TRACK_DOMAINS',
  TRACK_SAFETY = 'TRACK_SAFETY',
  TRACK_ECONOMY = 'TRACK_ECONOMY',
  TRACK_LEARN_MORE = 'TRACK_LEARN_MORE',
  TRACK_COMMUNITY_DESIGN = 'TRACK_COMMUNITY_DESIGN',
  TRACK_MOBILITY = 'TRACK_MOBILITY',
  TRACK_UTILITIES = 'TRACK_UTILITIES',
  TRACK_NATURAL_ENVIRONMENT = 'TRACK_NATURAL_ENVIRONMENT',
  TRACK_PARKS_AND_RECREATION = 'TRACK_PARKS_AND_RECREATION',
  TRACK_HEALTH_AND_WELLNESS = 'TRACK_HEALTH_AND_WELLNESS',
  TRACK_EDUCATION_ARTS_AND_CULTURE = 'TRACK_EDUCATION_ARTS_AND_CULTURE',
  TRACK_INCLUSIVITY_AND_ENGAGEMENT = 'TRACK_INCLUSIVITY_AND_ENGAGEMENT',
  TRACK_FINANCE = 'TRACK_FINANCE',
  TRACK_DASHBOARD = 'TRACK_DASHBOARD',
  POLCO_AI_LEARN_MORE = 'POLCO_AI_LEARN_MORE',
  ENGAGE_LEARN_MORE = 'ENGAGE_LEARN_MORE',
  ASSESS_LEARN_MORE = 'ASSESS_LEARN_MORE',
  POLL_LEARN_MORE = 'POLL_LEARN_MORE',
  SURVEY_LEARN_MORE = 'SURVEY_LEARN_MORE',
  LIVE_LEARN_MORE = 'LIVE_LEARN_MORE',
  BUDGET_LEARN_MORE = 'BUDGET_LEARN_MORE',
  HOUSING_LEARN_MORE = 'HOUSING_LEARN_MORE',
  PRIORITIZE_LEARN_MORE = 'PRIORITIZE_LEARN_MORE',
  TAXPAYER_LEARN_MORE = 'TAXPAYER_LEARN_MORE',
  NCS_LEARN_MORE = 'NCS_LEARN_MORE',
  NCSS_LEARN_MORE = 'NCSS_LEARN_MORE',
  CASOA_LEARN_MORE = 'CASOA_LEARN_MORE',
  NBS_LEARN_MORE = 'NBS_LEARN_MORE',
  NLES_LEARN_MORE = 'NLES_LEARN_MORE',
  NES_LEARN_MORE = 'NES_LEARN_MORE',
  NESLE_LEARN_MORE = 'NESLE_LEARN_MORE',
  BENCHMARK_SURVEYS = 'BENCHMARK_SURVEYS',
  COMMUNITY_LIVABILITY = 'COMMUNITY_LIVABILITY',
  DEMO_DISPARITY = 'DEMO_DISPARITY',
  SAVED_DATA_POINTS = 'SAVED_DATA_POINTS',
  POLCO_AI = 'POLCO_AI',
  BA_LEARN_MORE = 'BA_LEARN_MORE',
  MANAGE_PLANS = 'MANAGE_PLANS',
  FEATURE_SPOTLIGHT = 'FEATURE_SPOTLIGHT',
}

export const nameForSidebarButtonType: Record<SidebarButtonType, string> = {
  [SidebarButtonType.HOMEPAGE]: 'Polco Home',
  [SidebarButtonType.CONFIGURATION]: 'Configuration',
  [SidebarButtonType.CONTENT]: 'Content',
  [SidebarButtonType.PARTICIPANTS]: 'Participants',
  [SidebarButtonType.POLCO_LIBRARY]: 'Polco Library',
  [SidebarButtonType.PROFILE]: 'Profile',
  [SidebarButtonType.IDEA_SUBMISSIONS]: 'Idea Submissions',
  [SidebarButtonType.OUTREACH]: 'Outreach',
  [SidebarButtonType.SUPERADMIN]: 'Superadmin',
  [SidebarButtonType.BENCHMARK_REQUESTS]: 'Benchmark Requests',
  [SidebarButtonType.SETTINGS]: 'Settings',
  [SidebarButtonType.POLCO_PLANS]: 'Polco Plans',
  [SidebarButtonType.TRACK_OVERVIEW]: 'Overview',
  [SidebarButtonType.TRACK_DOMAINS]: 'Activate Local Data',
  [SidebarButtonType.TRACK_SAFETY]: domainLabels.SAFETY,
  [SidebarButtonType.TRACK_ECONOMY]: domainLabels.ECONOMY,
  [SidebarButtonType.TRACK_COMMUNITY_DESIGN]: domainLabels.COMMUNITY_DESIGN,
  [SidebarButtonType.TRACK_HEALTH_AND_WELLNESS]: domainLabels.HEALTH_AND_WELLNESS,
  [SidebarButtonType.TRACK_INCLUSIVITY_AND_ENGAGEMENT]:
    domainLabels.INCLUSIVITY_AND_ENGAGEMENT,
  [SidebarButtonType.TRACK_EDUCATION_ARTS_AND_CULTURE]:
    domainLabels.EDUCATION_ARTS_CULTURE,
  [SidebarButtonType.TRACK_MOBILITY]: domainLabels.MOBILITY,
  [SidebarButtonType.TRACK_UTILITIES]: domainLabels.UTILITIES,
  [SidebarButtonType.TRACK_PARKS_AND_RECREATION]: domainLabels.PARKS_AND_RECREATION,
  [SidebarButtonType.TRACK_NATURAL_ENVIRONMENT]: domainLabels.NATURAL_ENVIRONMENT,
  [SidebarButtonType.TRACK_FINANCE]: domainLabels.FINANCE,
  [SidebarButtonType.TRACK_DASHBOARD]: 'Track Dashboard',
  [SidebarButtonType.POLCO_AI_LEARN_MORE]: 'Learn More about Polco AI',
  [SidebarButtonType.TRACK_LEARN_MORE]: 'Learn More',
  [SidebarButtonType.ENGAGE_LEARN_MORE]: 'Learn More',
  [SidebarButtonType.ASSESS_LEARN_MORE]: 'Learn More',
  [SidebarButtonType.POLL_LEARN_MORE]: 'Polls',
  [SidebarButtonType.SURVEY_LEARN_MORE]: 'Surveys ',
  [SidebarButtonType.LIVE_LEARN_MORE]: 'Live Event',
  [SidebarButtonType.BUDGET_LEARN_MORE]: 'Budget Simulation',
  [SidebarButtonType.HOUSING_LEARN_MORE]: 'Housing Simulation',
  [SidebarButtonType.PRIORITIZE_LEARN_MORE]: 'Prioritization',
  [SidebarButtonType.TAXPAYER_LEARN_MORE]: 'Taxpayer Receipt',
  [SidebarButtonType.NCSS_LEARN_MORE]: 'Learn More',
  [SidebarButtonType.NCS_LEARN_MORE]: 'Learn More',
  [SidebarButtonType.CASOA_LEARN_MORE]: 'Learn More',
  [SidebarButtonType.NBS_LEARN_MORE]: 'Learn More',
  [SidebarButtonType.NLES_LEARN_MORE]: 'Learn More',
  [SidebarButtonType.NES_LEARN_MORE]: 'Learn More',
  [SidebarButtonType.NESLE_LEARN_MORE]: 'Learn More',
  [SidebarButtonType.BENCHMARK_SURVEYS]: 'Benchmark Surveys',
  [SidebarButtonType.COMMUNITY_LIVABILITY]: 'Community Livability',
  [SidebarButtonType.DEMO_DISPARITY]: 'Community Disparity',
  [SidebarButtonType.SAVED_DATA_POINTS]: 'Saved Data Points',
  [SidebarButtonType.POLCO_AI]: 'Polco AI',
  [SidebarButtonType.FEATURE_SPOTLIGHT]: 'New Product Features',
  [SidebarButtonType.BA_LEARN_MORE]: 'Learn More',
  [SidebarButtonType.MANAGE_PLANS]: 'My Plan',
};

export const regexForSidebarButtonType: Record<SidebarButtonType, RegExp | null> = {
  [SidebarButtonType.HOMEPAGE]: null,
  [SidebarButtonType.PARTICIPANTS]: new RegExp('participants'),
  [SidebarButtonType.CONFIGURATION]: new RegExp('configuration'),
  [SidebarButtonType.CONTENT]: new RegExp('content'),
  [SidebarButtonType.POLCO_LIBRARY]: new RegExp('library'),
  [SidebarButtonType.PROFILE]: new RegExp('profile'),
  [SidebarButtonType.IDEA_SUBMISSIONS]: new RegExp('idea-submissions'),
  [SidebarButtonType.OUTREACH]: new RegExp('outreach'),
  [SidebarButtonType.SUPERADMIN]: new RegExp('dashboard'),
  [SidebarButtonType.BENCHMARK_REQUESTS]: new RegExp('benchmark-request'),
  [SidebarButtonType.SETTINGS]: new RegExp('settings'),
  [SidebarButtonType.POLCO_PLANS]: new RegExp('store'),
  [SidebarButtonType.TRACK_OVERVIEW]: new RegExp('overview'),
  [SidebarButtonType.TRACK_DOMAINS]: new RegExp('domains'),
  [SidebarButtonType.TRACK_SAFETY]: new RegExp('safety'),
  [SidebarButtonType.TRACK_ECONOMY]: new RegExp('economy'),
  [SidebarButtonType.TRACK_COMMUNITY_DESIGN]: new RegExp('community-design'),
  [SidebarButtonType.TRACK_HEALTH_AND_WELLNESS]: new RegExp('health-and-wellness'),
  [SidebarButtonType.TRACK_INCLUSIVITY_AND_ENGAGEMENT]: new RegExp(
    'inclusivity-and-engagement'
  ),
  [SidebarButtonType.TRACK_EDUCATION_ARTS_AND_CULTURE]: new RegExp(
    'education-arts-and-culture'
  ),
  [SidebarButtonType.TRACK_MOBILITY]: new RegExp('mobility'),
  [SidebarButtonType.TRACK_UTILITIES]: new RegExp('utilities'),
  [SidebarButtonType.TRACK_NATURAL_ENVIRONMENT]: new RegExp('natural-environment'),
  [SidebarButtonType.TRACK_PARKS_AND_RECREATION]: new RegExp('parks-and-recreation'),
  [SidebarButtonType.TRACK_FINANCE]: new RegExp('finance'),
  [SidebarButtonType.TRACK_DASHBOARD]: new RegExp('track/dashboard'),
  [SidebarButtonType.POLCO_AI_LEARN_MORE]: new RegExp('polco-ai/learn-more'),
  [SidebarButtonType.TRACK_LEARN_MORE]: new RegExp('track/learn-more'),
  [SidebarButtonType.ENGAGE_LEARN_MORE]: new RegExp('engage/learn-more'),
  [SidebarButtonType.ASSESS_LEARN_MORE]: new RegExp('assess/learn-more'),
  [SidebarButtonType.POLL_LEARN_MORE]: new RegExp('polls/learn-more'),
  [SidebarButtonType.SURVEY_LEARN_MORE]: new RegExp('surveys/learn-more'),
  [SidebarButtonType.LIVE_LEARN_MORE]: new RegExp('live/learn-more'),
  [SidebarButtonType.BUDGET_LEARN_MORE]: new RegExp('budget/learn-more'),
  [SidebarButtonType.HOUSING_LEARN_MORE]: new RegExp('housing/learn-more'),
  [SidebarButtonType.PRIORITIZE_LEARN_MORE]: new RegExp('prioritize/learn-more'),
  [SidebarButtonType.TAXPAYER_LEARN_MORE]: new RegExp(
    'taxpayer-receipts/learn-more'
  ),
  [SidebarButtonType.NCSS_LEARN_MORE]: new RegExp('ncs-s/learn-more'),
  [SidebarButtonType.NCS_LEARN_MORE]: new RegExp('ncs/learn-more'),
  [SidebarButtonType.CASOA_LEARN_MORE]: new RegExp('casoa/learn-more'),
  [SidebarButtonType.NBS_LEARN_MORE]: new RegExp('nbs/learn-more'),
  [SidebarButtonType.NLES_LEARN_MORE]: new RegExp('nles/learn-more'),
  [SidebarButtonType.NES_LEARN_MORE]: new RegExp('nes/learn-more'),
  [SidebarButtonType.NESLE_LEARN_MORE]: new RegExp('nes-le/learn-more'),
  [SidebarButtonType.BENCHMARK_SURVEYS]: new RegExp('benchmark-surveys'),
  [SidebarButtonType.COMMUNITY_LIVABILITY]: new RegExp('community-livability'),
  [SidebarButtonType.DEMO_DISPARITY]: new RegExp('community-disparity'),
  [SidebarButtonType.SAVED_DATA_POINTS]: new RegExp('saved-data-points'),
  [SidebarButtonType.POLCO_AI]: new RegExp('ask-ai'),
  [SidebarButtonType.BA_LEARN_MORE]: new RegExp('balancing-act/learn-more'),
  [SidebarButtonType.MANAGE_PLANS]: new RegExp('store'),
  [SidebarButtonType.FEATURE_SPOTLIGHT]: new RegExp('feature-spotlight/learn-more'),
};

export function sidebarButtonCssClass(type: SidebarButtonType | string): string {
  return `pn-admin-sidebar-button-${type.toLowerCase()}`;
}

export function sidebarButtonCssSelector(type: SidebarButtonType): string {
  return `.${sidebarButtonCssClass(type)}`;
}

export function email(v: string) {
  return v && !EMAIL_VALIDATION_REGEX.test(v)
    ? 'Please enter your email address in format: yourname@example.com'
    : undefined;
}

export interface ShareableContentInput {
  readonly isPaidContent: boolean;
  readonly setId: ClientQuestionSetId;
  readonly tags: readonly {
    readonly name: string;
  }[];
  readonly shareableContentType: ShareableContentType;
  readonly adminDescription: string | null;
  readonly domains: readonly ShareableContentDomains[];
}

export enum ReportType {
  OVERVIEW = 'Overview Report',
  BREAKDOWNS = 'Breakdown Report',
  OVERVIEW_AND_BREAKDOWNS = 'Overview and Breakdown Report',
}

export interface ShareableSetInfo {
  readonly description: string;
  readonly questionsCount: number;
  readonly name: string;
  readonly requiresPurchase: boolean;
  readonly isPaidContent: boolean;
}

export const TRACKING_EMAIL_PARAMETER = 'track_email';
