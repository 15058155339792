import { StatisticType, DateLevel } from '@polco-us/types';
import { TrackVariable } from 'core';
import _, { floor, range } from 'lodash';
import React from 'react';
import { ScaleSpec } from '@nivo/scales';
import { DomainIndicatorsFipsAreaRow } from '../domain-indicators-group-wrapper';

export function useLineChartScale(args: {
  readonly data: readonly DomainIndicatorsFipsAreaRow[];
  readonly goalLine?: number;
  readonly variable: Pick<
    TrackVariable,
    'id' | 'name' | 'valueType' | 'direction' | 'dateLevel' | 'statisticType'
  >;
}) {
  const { data, goalLine, variable } = args;
  return React.useMemo<{
    readonly yAxisValues: number[];
    readonly yAxisScaleProps: ScaleSpec;
    readonly xAxisValues: number[] | Date[];
    readonly xAxisScaleProps: ScaleSpec;
  }>(() => {
    const allData = _.without(
      [
        ...data.flatMap((d) =>
          d.trackAreaData.performanceData?.map(({ value }) => value)
        ),
        goalLine,
      ],
      undefined
    );
    const minY =
      variable.statisticType === StatisticType.INDEX ? 0 : _.min(allData) ?? 0;
    const maxY =
      variable.statisticType === StatisticType.INDEX ? 90 : _.max(allData) ?? 100;

    const yValues = getYAxisValues(minY, maxY);
    const yScale: ScaleSpec = {
      type: 'linear',
      min: Math.min(...yValues),
      max: Math.max(...yValues),
    };
    const minX = _.min(
      data.flatMap(
        (d) =>
          d.trackAreaData.performanceData?.map(({ recordedAt }) => recordedAt) ?? []
      )
    );

    const maxX = _.max(
      data.flatMap(
        (d) =>
          d.trackAreaData.performanceData?.map(({ recordedAt }) => recordedAt) ?? []
      )
    );
    const xScale: ScaleSpec =
      variable.dateLevel === DateLevel.YEAR
        ? {
            type: 'linear',
            min: minX?.getUTCFullYear() ?? new Date().getUTCFullYear() - 1,
            max: maxX?.getUTCFullYear() ?? new Date().getUTCFullYear(),
          }
        : { type: 'time', min: minX, max: maxX };

    const xValues = getXAxisValuesByDateLevel(minX, maxX, variable.dateLevel);

    return {
      yAxisValues: yValues,
      yAxisScaleProps: yScale,
      xAxisValues: xValues,
      xAxisScaleProps: xScale,
    };
  }, [variable, data, goalLine]);
}

function getYAxisValues(minY: number, maxY: number) {
  if (maxY - minY <= 5) {
    return range(_.floor(maxY) <= 9 ? 0 : floor(minY), maxY + 1, 0.5);
  }
  const rangeMin = _.floor(minY / 10) * 10;
  const rangeMax = _.floor((maxY + 10) / 10) * 10;

  const rangeLength = rangeMax - rangeMin;
  const modNumber =
    rangeLength % 10 ? _.floor(rangeLength / 10) + 1 : _.floor(rangeLength / 10);
  const rangeValues = range(rangeMin, rangeMax, modNumber);
  return _.uniq([
    rangeValues[0] - modNumber < 0 ? 0 : rangeValues[0] - modNumber,
    ...rangeValues,
    rangeValues[rangeValues.length - 1] + modNumber,
  ]);
}

function getXAxisValuesByDateLevel(
  minX: Date | undefined,
  maxX: Date | undefined,
  dateLevel: DateLevel
) {
  if (!minX || !maxX) {
    return [];
  }
  const years = range(minX.getUTCFullYear(), maxX.getUTCFullYear() + 1).map(
    (v) => new Date(`01/01/${v}`)
  );
  const modNumber = _.floor(years.length / 10) * 2;
  if (modNumber > 0) {
    const tenYearsRange = range(
      minX.getUTCFullYear(),
      maxX.getUTCFullYear() - (maxX.getUTCFullYear() % modNumber) + modNumber
    ).map((v) => new Date(`01/01/${v}`));
    const yearsMod = tenYearsRange.filter((y) => {
      return y.getUTCFullYear() % modNumber === 0;
    });
    switch (dateLevel) {
      case DateLevel.MONTH:
        return yearsMod;
      case DateLevel.YEAR:
        return yearsMod.map((v) => v.getUTCFullYear());
    }
  }

  switch (dateLevel) {
    case DateLevel.MONTH: {
      const yearRange = range(minX.getUTCFullYear(), maxX.getUTCFullYear() + 1);
      const quarterMonths = yearRange.flatMap((y) => {
        const jan = new Date(`01/01/${y}`);
        const apr = new Date(`04/01/${y}`);
        const jul = new Date(`07/01/${y}`);
        const oct = new Date(`10/01/${y}`);
        return [jan, apr, jul, oct];
      });
      if (quarterMonths.length > 16) {
        return years;
      }
      return quarterMonths;
    }
    case DateLevel.YEAR:
      return years.map((v) => v.getUTCFullYear());
  }
}
