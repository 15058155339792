import React from 'react';
import { ClickableDiv } from '../base';
import icon from './icon.svg';
import { useAiPrompt } from './hook/use-ai-prompt';
import './styles.scss';
import { KnownFlag, useFlagEnabled } from 'client/shared/contexts/flags-context';
import { useSearchParams } from 'client/shared/hooks';
import { AiAssistantId } from '@polco-us/types';
import { useAiAssistantSession } from 'client/admin/ai/hooks/use-ai-assistant-session';
import { AiQuickActionsUpgradeModal } from './upgrade-modal';

interface Props {
  readonly promptKey: string;
  readonly className?: string;
  readonly showBorder?: boolean;
}

const baseClass = 'pn-ai-quick-action-button';

export const AIQuickAction: React.FC<Props> = (p) => {
  const quickActionsEnabled = useFlagEnabled(KnownFlag.AI_QUICK_ACTIONS);
  const promptInfo = useAiPrompt(p.promptKey);
  const searchParams = useSearchParams();

  const { queryLimitReached } = useAiAssistantSession({
    assistantId: AiAssistantId.POLLY,
  });

  const [displayQueryLimitModal, setDisplayQueryLimitModal] = React.useState(false);

  const onClickQuickAction = React.useCallback(() => {
    if (queryLimitReached) {
      setDisplayQueryLimitModal(true);
    } else {
      searchParams.setPollyPrompt(promptInfo?.prompt ?? '');
    }
  }, [queryLimitReached, promptInfo, searchParams]);

  if (!promptInfo || !quickActionsEnabled) {
    return null;
  }

  return (
    <>
      <ClickableDiv
        action={onClickQuickAction}
        className={`${baseClass} ${p.className ?? ''} no-show-in-image ${p.showBorder ? 'border border-jungle' : ''} d-flex align-items-center px-3 py-2`}
      >
        <img alt={'AI Icon'} className="mr-2" src={icon} />
        <p className="font-weight-bold text-jungle">{promptInfo.labelQuestion}</p>
      </ClickableDiv>
      <AiQuickActionsUpgradeModal
        isOpen={displayQueryLimitModal}
        labelQuestion={promptInfo.labelQuestion}
        onClose={() => setDisplayQueryLimitModal(false)}
      />
    </>
  );
};
