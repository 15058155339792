import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import './styles.scss';
import {
  Btn,
  ButtonTypes,
  MaterialIcon,
  MaterialIconName,
} from 'client/shared/components/base';
import { MaterialIconType } from 'client/shared/components/base/material-icon';
import { AiAssistantId } from '@polco-us/types';
import { useAiAssistantFileUpload } from '../../hooks/use-ai-file-upload';
import { Result } from 'core';
import { Box, LinearProgress } from '@material-ui/core';
export const MAX_FILE_SIZE_MB = 5;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // 5 MB in bytes

const ACCEPTED_FILE_TYPES = {
  'text/x-c': ['.c'],
  'text/x-c++': ['.cpp'],
  'text/x-csharp': ['.cs'],
  'text/css': ['.css'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'text/x-golang': ['.go'],
  'text/html': ['.html'],
  'text/x-java': ['.java'],
  'text/javascript': ['.js'],
  'application/json': ['.json'],
  'text/markdown': ['.md'],
  'application/pdf': ['.pdf'],
  'text/x-php': ['.php'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx',
  ],
  'text/x-python': ['.py'],
  'text/x-script.python': ['.py'],
  'text/x-ruby': ['.rb'],
  'application/x-sh': ['.sh'],
  'text/x-tex': ['.tex'],
  'application/typescript': ['.ts'],
  'text/plain': ['.txt'],
};

const formatFileSize = (size: number): string => {
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(size) / Math.log(1024));

  const fileSize = size / Math.pow(1024, i);

  // Show whole number for bytes, 1 decimal for KB and above
  const formattedSize = i === 0 ? Math.floor(fileSize) : fileSize.toFixed(1);

  return `${formattedSize} ${units[i]}`;
};

const getMaterialIconPropsForUploadFeedback = (
  uploadComplete: boolean,
  error: boolean
) => {
  let colorClass = '';
  let iconName;
  let iconType: MaterialIconType = 'material-icons';

  if (error) {
    colorClass = 'text-error';
    iconName = MaterialIconName.ERROR;
    iconType = 'material-symbols-outlined';
  } else if (uploadComplete) {
    colorClass = 'text-polco-green';
    iconName = MaterialIconName.CHECK_CIRCLE_OUTLINE;
  } else {
    iconName = MaterialIconName.ARROW_UPLOAD_PROGRESS;
    iconType = 'material-symbols-outlined';
  }
  return { colorClass, iconName, iconType };
};

interface AIFileUploadProps {
  readonly sessionId: string;
  readonly assistantId: AiAssistantId;
}

export const EmbeddedFileUpload: React.FC<AIFileUploadProps> = (p) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {
    data: uploadData,
    loading,
    uploadFile,
  } = useAiAssistantFileUpload({
    assistantId: p.assistantId,
    sessionId: p.sessionId,
  });

  const onDropAccepted = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      setSelectedFile(file);
      const base64 = await convertToBase64(file);
      const result = await uploadFile(base64, file.name);
      if (Result.isFailure(result)) {
        setErrorMessage('Error uploading file');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const onDropRejected = useCallback((rejectedFiles) => {
    const readableErrorMsg =
      fileRejectionMessages[rejectedFiles[0].errors[0].code] ??
      'Error uploading file';

    setErrorMessage(readableErrorMsg);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ACCEPTED_FILE_TYPES,
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE_BYTES,
    multiple: false,
    onDropAccepted,
    onDropRejected,
  });

  const showFileDropzone = errorMessage === null && !selectedFile && !uploadData;
  const { colorClass, iconName, iconType } = getMaterialIconPropsForUploadFeedback(
    !!uploadData,
    !!errorMessage
  );

  return (
    <div>
      {showFileDropzone && (
        <div {...getRootProps()} className="pn-ai-file-upload">
          <MaterialIcon className="text-jungle" icon={MaterialIconName.PUBLISH} />
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className="text-jungle font-size-lg font-weight-bold">
              Drop the file here...
            </p>
          ) : (
            <>
              <p className="text-jungle font-size-lg font-weight-bold">
                Drag and drop a file here
              </p>
              <Btn action={() => {}} className="mt-3" type={ButtonTypes.SECONDARY}>
                Browse Files
              </Btn>
            </>
          )}
        </div>
      )}

      {selectedFile && (
        <div className="pn-ai-file-upload-feedback d-flex flex-row w-100 align-items-center">
          <MaterialIcon
            className={`mr-1 ${colorClass}`}
            icon={iconName}
            iconType={iconType}
            style={{ fontSize: '32px' }}
          />
          <div className="d-flex flex-grow-1 flex-column justify-content-center font-size-sm font-weight-bold align-items-start">
            <div>
              {selectedFile.name}
              {uploadData && (
                <span className="font-weight-normal pl-2 text-gray-40">
                  {formatFileSize(selectedFile.size)}
                </span>
              )}
            </div>
            {loading && (
              <Box style={{ width: '100%' }}>
                <LinearProgress
                  className="mr-1 my-2"
                  classes={{ bar: 'custom-linear-bar' }}
                  style={{ height: '6px', borderRadius: '4px' }}
                  variant="indeterminate"
                />
              </Box>
            )}
            {uploadData && (
              <div className="text-brand-d font-weight-normal">upload complete</div>
            )}
            {errorMessage && (
              <div className="text-error font-weight-normal">{errorMessage}</div>
            )}
          </div>
        </div>
      )}

      {errorMessage && (
        <div className="d-flex flex-column mt-3">
          <div className="font-size-sm">{errorMessage}</div>
          <Btn
            action={() => {
              setErrorMessage(null);
              setSelectedFile(null);
            }}
            className="mt-2"
            type={ButtonTypes.SECONDARY}
          >
            Upload File
          </Btn>
        </div>
      )}
    </div>
  );
};

const convertToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

const fileRejectionMessages: Record<string, string> = {
  'file-too-large': `File size exceeds the ${MAX_FILE_SIZE_MB}MB limit.`,
  'file-invalid-type': 'Sorry, that is not a supported file type.',
  'too-many-files': 'Please upload one file at a time.',
};
